import React, { useState } from 'react';

import classNames from 'classnames';
import { Check, Copy } from 'lucide-react';
import PropTypes from 'prop-types';

import { generateDistanceString, generateThumbnailUrlFromOriginalUrl } from '../utils';

import ImagePopup from './ImagePopup';

const ComparisonImageItem = ({
  comparisonObject,
  geocodedLocation,
  onCoordinateClick,
  addToObserver,
  className = '',
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isAfterImagePopupOpen, setIsAfterImagePopupOpen] = useState(false);
  const [isBeforeImagePopupOpen, setIsBeforeImagePopupOpen] = useState(false);

  const [imageObjectBefore, imageObjectAfter] = comparisonObject;

  const thumbnailSourceBefore = generateThumbnailUrlFromOriginalUrl(imageObjectBefore.url);
  const thumbnailSourceAfter = generateThumbnailUrlFromOriginalUrl(imageObjectAfter.url);

  // NOTE: we can use the coordinates of the first one
  const [latitude, longitude] = imageObjectBefore.coordinates;
  const isCoordinateClickDisabled = latitude === null || longitude === null;

  const handleOnCopyToPasteboard = () => {
    if (!isCoordinateClickDisabled) {
      navigator.clipboard.writeText(`${latitude}, ${longitude}`);

      // Display a checkmark to let the user know they have copied it
      setIsCopied(true);

      // Undo the checkmark after a while
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    }
  };

  // NOTE: we can use the coordinates of the first one
  const handleOnCoordinateClick = () => {
    onCoordinateClick(imageObjectBefore.coordinates);
  };

  const handleOnPreviewLoad = (event) => {
    event.target.style.opacity = 1;
  };

  const handleOnBeforeImagePopupClose = () => {
    setIsBeforeImagePopupOpen(false);
  };

  const handleOnAfterImagePopupClose = () => {
    setIsAfterImagePopupOpen(false);
  };

  const handleOnBeforePreviewClick = () => {
    setIsBeforeImagePopupOpen(true);

    // Check if the popup container exists and reset its scroll position
    const popupContainer = document.querySelector('.popup-container');
    if (popupContainer) {
      popupContainer.scrollTop = 0;
    }
  };

  const handleOnAfterPreviewClick = () => {
    setIsAfterImagePopupOpen(true);

    // Check if the popup container exists and reset its scroll position
    const popupContainer = document.querySelector('.popup-container');
    if (popupContainer) {
      popupContainer.scrollTop = 0;
    }
  };

  return (
    <>
      <div
        className={classNames('max-w-lg rounded-md border border-transparent', className)}
        // NOTE: we can use the ID of the first one
        id={`image-item-${imageObjectBefore.id}`}
      >
        <div className="flex flex-row">
          <div
            className="image-placeholder comparison-image-item-before relative flex w-1/2 cursor-pointer flex-row items-center justify-center rounded-tl-md border-b border-slate-300 bg-slate-50 text-xl text-slate-300 dark:border-slate-700 dark:bg-slate-400"
            style={{ paddingBottom: '60%' }}
            onClick={handleOnBeforePreviewClick}
          >
            <div className="absolute top-1/2 -mt-4">
              <span>Loading</span>
              <span className="loadingDots" />
            </div>
            <img
              className="absolute left-0 top-0 h-full max-h-[800px] w-full max-w-[1000px] rounded-tl-md bg-slate-100 object-cover opacity-0 transition-opacity duration-500"
              data-src={thumbnailSourceBefore}
              alt={imageObjectBefore.note}
              ref={addToObserver}
              loading="lazy"
              onLoad={handleOnPreviewLoad}
            />
          </div>
          <div
            className="image-placeholder comparison-image-item-after relative flex w-1/2 cursor-pointer flex-row items-center justify-center rounded-tr-md border-b border-slate-300 bg-slate-50 text-xl text-slate-300 dark:border-slate-700 dark:bg-slate-400"
            style={{ paddingBottom: '60%' }}
            onClick={handleOnAfterPreviewClick}
          >
            <div className="absolute top-1/2 -mt-4">
              <span>Loading</span>
              <span className="loadingDots" />
            </div>
            <img
              className="absolute left-0 top-0 h-full max-h-[800px] w-full max-w-[1000px] rounded-tr-md bg-slate-100 object-cover opacity-0 transition-opacity duration-500"
              data-src={thumbnailSourceAfter}
              alt={imageObjectAfter.note}
              ref={addToObserver}
              loading="lazy"
              onLoad={handleOnPreviewLoad}
            />
          </div>
        </div>
        <div className="mt-0 w-full rounded-b-md bg-slate-100 py-1.5 pl-3 pr-2 text-center dark:bg-slate-600 dark:text-slate-200">
          {isCoordinateClickDisabled ? (
            <div className="flex w-full flex-col items-center">
              <button
                className="text-xs text-slate-700 hover:underline disabled:cursor-not-allowed dark:text-slate-200"
                title="No location available"
                disabled
              >
                <span className="font-medium">Coordinates: </span>
                <span className="text-slate-500 dark:text-slate-500">N/A</span>
              </button>
            </div>
          ) : (
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-col items-start">
                <button
                  className="text-xs text-slate-700 hover:underline  dark:text-slate-200"
                  onClick={handleOnCoordinateClick}
                  title="Go to pinned location on map"
                >
                  <span className="font-medium">Coordinates: </span>
                  <span className="text-slate-700 dark:text-slate-200">
                    {latitude.toFixed(3)}, {longitude.toFixed(3)}
                  </span>
                </button>
                {geocodedLocation !== null && (
                  <div className="text-xs text-slate-700 dark:text-slate-200">
                    <span className="font-medium">Distance to address: </span>
                    <span>
                      {generateDistanceString(imageObjectBefore.coordinates, [
                        geocodedLocation.lat,
                        geocodedLocation.lng,
                      ])}
                    </span>
                  </div>
                )}
              </div>
              <button
                className={classNames(
                  'rounded-md p-1 text-slate-700 transition-colors hover:bg-slate-200 disabled:cursor-not-allowed dark:text-slate-200 dark:hover:bg-slate-800',
                  isCopied ? 'bg-slate-200 dark:bg-slate-800' : ''
                )}
                onClick={handleOnCopyToPasteboard}
                title="Copy coordinates to clipboard"
                disabled={isCopied}
              >
                {isCopied ? <Check className="text-green-600" size={16} /> : <Copy size={16} />}
              </button>
            </div>
          )}
        </div>
      </div>
      {isBeforeImagePopupOpen && (
        <ImagePopup src={imageObjectBefore.url} onClose={handleOnBeforeImagePopupClose} />
      )}
      {isAfterImagePopupOpen && (
        <ImagePopup src={imageObjectAfter.url} onClose={handleOnAfterImagePopupClose} />
      )}
    </>
  );
};

ComparisonImageItem.defaultProps = {
  geocodedLocation: null,
};

ComparisonImageItem.propTypes = {
  comparisonObject: PropTypes.arrayOf(PropTypes.object).isRequired,
  geocodedLocation: PropTypes.object,
  onCoordinateClick: PropTypes.func.isRequired,
  addToObserver: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

export default ComparisonImageItem;
