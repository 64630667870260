import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';

import { Amplify, API, Auth } from 'aws-amplify';
import classNames from 'classnames';
import L from 'leaflet';
import 'leaflet.vectorgrid';
import PropTypes from 'prop-types';

import withCustomAuthenticator from './CustomAuthWrapper';
import { searchCategoriesBasedOnImages } from './api';
import awsconfig from './aws-exports';
import CategorizationModal from './components/CategorizationModal';
import ImageGallery from './components/ImageGallery';
import Map from './components/Map';
import ReportCard from './components/ReportCard';
import SideBar from './components/SideBar';
import {
  CLASSIFICATION_LAYER_COLORS,
  DEFAULT_DATA_SOURCE,
  DEFAULT_IMAGE_GALLERY_WIDTH_IN_PX,
  IMAGE_GALLERY_PAGE_SIZE,
  MAX_RESULT_LAYER_STACK,
  RESULT_LAYER_COLOR_CLASSNAME_POOL,
  USERNAME_LOCALSTORAGE_KEY,
} from './constants';
import {
  createClusterIcon,
  createMarkersAndBoundingBoxes,
  intertwineLists,
  getLayersForDataSource,
} from './utils';

// Import module styles
import '@aws-amplify/ui-react/styles.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import './custom-leaflet-controls/leaflet-control-geocoder.css';
import './custom-leaflet-controls/leaflet-slider.css';
import './index.css';

// Import Leaflet plugins
import 'geojson-vt';
import 'leaflet-control-geocoder';
import 'leaflet-draw';
import 'leaflet.markercluster';
import './custom-leaflet-controls/leaflet-slider';

Amplify.configure(awsconfig);
API.configure(awsconfig);

// Generate unique fake IDs for each "classification category"
let nextClassificationCategoryId = 0;

const App = ({ signOut }) => {
  const mapRef = useRef(null);
  const imageGalleryRef = useRef(null);
  const drawnItemsRef = useRef(null);

  // Buffer that contains "the last few results"
  // NOTE: Length doesn't exceed MAX_RESULT_LAYER_STACK
  const individualResultsQueue = useRef([]);
  const comparisonResultsQueue = useRef([]);

  // Global State
  const [primaryDataSource, setPrimaryDataSource] = useState(DEFAULT_DATA_SOURCE);
  const [compareMode, setCompareMode] = useState(false);
  const [hasUserQueried, setHasUserQueried] = useState(false);
  const [isQueryLoading, setIsQueryLoading] = useState(false);

  // Resizer State
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(DEFAULT_IMAGE_GALLERY_WIDTH_IN_PX);

  // Map State
  const usedColors = useRef([]);
  const individualResultsLayers = useRef([]);
  const comparisonResultsLayers = useRef([]);
  const individualResultsLayerControlRef = useRef(null);
  const comparisonResultsLayerControlRef = useRef(null);
  const classificationResultsLayerControlRef = useRef(null);
  const sliderControlRef = useRef(null);
  const baseLayerControlRef = useRef(null);
  const additionalBaseLayersRef = useRef([]);
  const geoboundaryRef = useRef(null);
  const [geocodedLocation, setGeocodedLocation] = useState(null);
  const [geojsonLink, setGeojsonLink] = useState('');

  // ImageGallery State
  const [individualPageData, setIndividualPageData] = useState([]);
  const [comparisonPageData, setComparisonPageData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  // Categorization Modal State
  const [isClassificationLoading, setIsClassificationLoading] = useState(false);
  const [activeClassificationCategoryIndex, setActiveClassificationCategoryIndex] = useState(0);
  const [classificationCategories, setClassificationCategories] = useState([]);

  const isCategorizationModalOpen = classificationCategories.length > 0;

  const selectedImageIds = useMemo(() => {
    const imageIds = [];

    for (let i = 0; i < classificationCategories.length; i++) {
      const { imageObjects } = classificationCategories[i];
      for (let j = 0; j < imageObjects.length; j++) {
        const { id } = imageObjects[j];
        imageIds.push(id);
      }
    }

    return imageIds;
  }, [classificationCategories]);

  // Report Card State
  const [scoreQueue, setScoreQueue] = useState([]);
  const isReportCardShowing = scoreQueue.length > 0;
  const [reportCardMode, setReportCardMode] = useState('adjacentRisk');

  const handleOnCategoryNameChange = (categoryId, newName) => {
    setClassificationCategories((previousCategories) =>
      previousCategories.map((category) => {
        if (category.id !== categoryId) {
          return category;
        }

        return {
          ...category,
          name: newName,
        };
      })
    );
  };

  const handleOnCategorizationModalPrev = () => {
    setActiveClassificationCategoryIndex((previousIndex) => Math.max(previousIndex - 1, 0));
  };

  const handleOnCategorizationModalNext = useCallback(() => {
    const isPointingAtTheLastOne =
      activeClassificationCategoryIndex === classificationCategories.length - 1;

    // Create a new one if the active one is the last one
    if (isPointingAtTheLastOne) {
      setClassificationCategories((previousCategories) => [
        ...previousCategories,
        {
          id: nextClassificationCategoryId++,
          name: '',
          imageObjects: [],
        },
      ]);
    }

    // Always move the index
    setActiveClassificationCategoryIndex((previousIndex) => previousIndex + 1);
  }, [activeClassificationCategoryIndex, classificationCategories.length]);

  const handleAddSelectedImage = useCallback(
    (imageId) => {
      const newImageObject = {
        id: imageId,
        url: decodeURIComponent(imageId),
      };

      // If there are no categories,
      // create a new one and add the image to it
      if (classificationCategories.length === 0) {
        setClassificationCategories([
          {
            id: nextClassificationCategoryId++,
            name: '',
            imageObjects: [newImageObject],
          },
        ]);

        return;
      }

      // If there are already categories,
      // add the image to the active category.
      setClassificationCategories((previousCategories) =>
        previousCategories.map((previousCategory, i) => {
          if (i !== activeClassificationCategoryIndex) {
            return previousCategory;
          }

          return {
            ...previousCategory,
            imageObjects: [...previousCategory.imageObjects, newImageObject],
          };
        })
      );
    },
    [activeClassificationCategoryIndex, classificationCategories.length === 0]
  );

  const handleRemoveImageObjectFromCategory = (categoryId, imageId) => {
    setClassificationCategories((previousCategories) =>
      previousCategories.map((previousCategory) => {
        if (previousCategory.id !== categoryId) {
          return previousCategory;
        }

        return {
          ...previousCategory,
          imageObjects: previousCategory.imageObjects.filter(
            (imageObject) => imageObject.id !== imageId
          ),
        };
      })
    );
  };

  const handleDeleteCategory = (categoryId) => {
    setClassificationCategories((previousCategories) =>
      previousCategories.filter((category) => category.id !== categoryId)
    );

    // NOTE: Since we only allow deleting the active category, we always subtract one
    setActiveClassificationCategoryIndex((previousIndex) => Math.max(previousIndex - 1, 0));
  };

  // TODO: handle empty names and empty imageobjects.....................
  // TODO: close modal after submitting? clear all data after submitting? clear legend and geojsons?
  const handleOnCategorizationModalCommit = useCallback(async () => {
    let geojsonsUrl;
    let categorizedImageObjects;

    setIsClassificationLoading(true);

    try {
      const response = await searchCategoriesBasedOnImages({
        dataSource: primaryDataSource,
        imageUrls: classificationCategories.map((category) =>
          category.imageObjects.map((imageObject) => imageObject.url)
        ),
      });

      geojsonsUrl = response.geojsonsUrl;
      categorizedImageObjects = response.results;

      // Add the category name to each imageObject
      categorizedImageObjects.forEach((category, index) => {
        category.forEach((imageObject) => {
          imageObject.category =
            classificationCategories[index].name.length > 0
              ? classificationCategories[index].name
              : `Category ${index + 1}`;
        });
      });

      // Merge all the imageObjects into a single list
      const interwinedList = intertwineLists(categorizedImageObjects);

      handleNewImageObjectResponse(interwinedList);

      // Remove oldest layer
      if (individualResultsQueue.current.length === MAX_RESULT_LAYER_STACK) {
        individualResultsQueue.current.pop();
      }

      // Append new results into the existing results queue
      individualResultsQueue.current.unshift(interwinedList);

      setIndividualPageData(interwinedList.slice(0, IMAGE_GALLERY_PAGE_SIZE));
      setPageIndex(0);

      setIsClassificationLoading(false);
    } catch (error) {
      console.error('Error while submitting classifiers:', error);
      setIsClassificationLoading(false);
      return;
    }

    // Store GeoJSON URL in case user wants to download it
    setGeojsonLink(geojsonsUrl);

    // fetch and parse geojsons
    const geoJsonsResponse = await fetch(geojsonsUrl);
    const geoJsonObjects = await geoJsonsResponse.json();

    // use first geojson to fit bounds
    const tempGeoJsonLayer = L.geoJson(geoJsonObjects[0]);
    mapRef.current.fitBounds(tempGeoJsonLayer.getBounds());

    geoJsonObjects.forEach((geoJsonObject, index) => {
      const legendItemText =
        classificationCategories[index].name.length > 0
          ? classificationCategories[index].name
          : `Category ${index + 1}`;

      const chosenColor = CLASSIFICATION_LAYER_COLORS[index % CLASSIFICATION_LAYER_COLORS.length];

      const geoJsonLayer = L.vectorGrid
        .slicer(geoJsonObject, {
          maxZoom: 18,
          rendererFactory: L.canvas.tile,
          vectorTileLayerStyles: {
            sliced: function (_, zoom) {
              const radius = zoom > 16 ? 20 : zoom > 14 ? 10 : zoom > 10 ? 2 : 0.01;

              return {
                fill: true,
                radius: radius,
                fillColor: chosenColor,
                fillOpacity: 0.5,
                color: chosenColor,
                weight: 0,
                opacity: 1,
              };
            },
          },
        })
        .addTo(mapRef.current);

      // Make GeoJSONs toggleable
      classificationResultsLayerControlRef.current.addOverlay(
        geoJsonLayer,
        `<span style="color: ${chosenColor};">${legendItemText}</span>`
      );
    });

    // Make the control visible
    classificationResultsLayerControlRef.current.getContainer().style.display = 'block';
  }, [classificationCategories, primaryDataSource]);

  const onBoundingBoxClick = useCallback(
    (imageId) => {
      let globalIndex = 0;
      let found = false;

      if (compareMode) {
        for (let i = 0; i < comparisonResultsQueue.current.length; i++) {
          const batch = comparisonResultsQueue.current[i];
          for (let j = 0; j < batch.length; j++) {
            // NOTE: This only happens because we only plot the "before" imageObject
            const [imageObject] = batch[j];

            // Break from inner loop
            if (imageId === imageObject.id) {
              found = true;
              break;
            }

            globalIndex += 1;
          }

          // Break from outer loop
          if (found) {
            break;
          }
        }
      } else {
        for (let i = 0; i < individualResultsQueue.current.length; i++) {
          const batch = individualResultsQueue.current[i];
          for (let j = 0; j < batch.length; j++) {
            const imageObject = batch[j];

            // Break from inner loop
            if (imageId === imageObject.id) {
              found = true;
              break;
            }

            globalIndex += 1;
          }

          // Break from outer loop
          if (found) {
            break;
          }
        }
      }

      // If for some reason a 'click' event was made
      // to a bbox that is no longer visible, exit early.
      if (!found) {
        console.error('Image with provided ID was not found:', imageId);
        return;
      }

      const newPageIndex = Math.floor(globalIndex / IMAGE_GALLERY_PAGE_SIZE);
      setPageIndex(newPageIndex);

      if (compareMode) {
        const globalArray = comparisonResultsQueue.current.flat();

        setComparisonPageData(
          globalArray.slice(
            newPageIndex * IMAGE_GALLERY_PAGE_SIZE,
            (newPageIndex + 1) * IMAGE_GALLERY_PAGE_SIZE
          )
        );
      } else {
        const globalArray = individualResultsQueue.current.flat();

        setIndividualPageData(
          globalArray.slice(
            newPageIndex * IMAGE_GALLERY_PAGE_SIZE,
            (newPageIndex + 1) * IMAGE_GALLERY_PAGE_SIZE
          )
        );
      }
    },
    [compareMode]
  );

  const handleNewImageObjectResponse = useCallback(
    (newImageObjects) => {
      // Prevent storing colors if there aren't any results to plot
      if (newImageObjects.length === 0) {
        return;
      }

      const newBoundingBoxes = [];
      const newMarkers = [];

      // Choose a color that hasn't been used yet (for the legend)
      const nonUsedColors = RESULT_LAYER_COLOR_CLASSNAME_POOL.filter(
        (color) => !usedColors.current.includes(color)
      );
      const randomIndex = Math.floor(Math.random() * nonUsedColors.length);
      const randomColor = nonUsedColors[randomIndex];

      // Remove the oldest color in the legend (for it to be reused)
      if (usedColors.current.length >= MAX_RESULT_LAYER_STACK) {
        usedColors.current.shift();
      }

      // Keep track of the colors used (for the legend)
      usedColors.current.push(randomColor);

      const currentSliderValue = Number(sliderControlRef.current.slider.value);

      // Create new markers with bounding boxes on the map
      for (let k = 0; k < Math.min(currentSliderValue, newImageObjects.length); k++) {
        const imageObject = newImageObjects[k];
        const { boundingBox, marker } = createMarkersAndBoundingBoxes({
          imageObject,
          onBoundingBoxClick,
          color: randomColor,
        });

        if (boundingBox) {
          newBoundingBoxes.push(boundingBox);
        }

        if (marker) {
          newMarkers.push(marker);
        }
      }

      const newBoundingBoxesGroup = L.featureGroup(newBoundingBoxes);
      const newMarkerClusterGroup = L.markerClusterGroup({
        iconCreateFunction: (cluster) => createClusterIcon(cluster, randomColor),
      }).addLayers(newMarkers);

      const newResultLayer = L.layerGroup([newBoundingBoxesGroup, newMarkerClusterGroup]).addTo(
        mapRef.current
      );

      if (newMarkerClusterGroup.getLayers().length > 0) {
        // Delete the oldest result layer to make room for the new one
        if (individualResultsLayers.current.length >= MAX_RESULT_LAYER_STACK) {
          const layerIndex = individualResultsLayers.current.length - MAX_RESULT_LAYER_STACK;
          const layerToRemove = individualResultsLayers.current[layerIndex];
          individualResultsLayerControlRef.current.removeLayer(layerToRemove);
          layerToRemove.remove();
        }

        // Zoom into results
        mapRef.current.fitBounds(newMarkerClusterGroup.getBounds());

        // Get the most recent search query
        // NOTE: This will break if the app starts without a single QueryHistoryItem
        const queryText = document.querySelector('#query-history-item').title;

        // Store toggleable result layer
        individualResultsLayerControlRef.current.addOverlay(
          newResultLayer,
          `<span class="${randomColor.legendTextClassName}">${queryText}</span>`
        );

        individualResultsLayers.current.push(newResultLayer);
      }

      // Hide the control if there's nothing to display (and viceversa)
      individualResultsLayerControlRef.current.getContainer().style.display =
        individualResultsLayers.current.length === 0 ? 'none' : 'block';
    },
    [onBoundingBoxClick]
  );

  const handleNewComparisonObjectResponse = useCallback(
    (newComparisonObjects) => {
      // Prevent storing colors if there aren't any results to plot
      if (newComparisonObjects.length === 0) {
        return;
      }

      const newBoundingBoxes = [];
      const newMarkers = [];

      // Choose a color that hasn't been used yet (for the legend)
      const nonUsedColors = RESULT_LAYER_COLOR_CLASSNAME_POOL.filter(
        (color) => !usedColors.current.includes(color)
      );
      const randomIndex = Math.floor(Math.random() * nonUsedColors.length);
      const randomColor = nonUsedColors[randomIndex];

      // Remove the oldest color in the legend (for it to be reused)
      if (usedColors.current.length >= MAX_RESULT_LAYER_STACK) {
        usedColors.current.shift();
      }

      // Keep track of the colors used (for the legend)
      usedColors.current.push(randomColor);

      const currentSliderValue = Number(sliderControlRef.current.slider.value);

      // Create new markers with bounding boxes on the map
      for (let k = 0; k < Math.min(currentSliderValue, newComparisonObjects.length); k++) {
        const [imageObject] = newComparisonObjects[k];

        const { boundingBox, marker } = createMarkersAndBoundingBoxes({
          imageObject,
          onBoundingBoxClick,
          color: randomColor,
        });

        if (boundingBox) {
          newBoundingBoxes.push(boundingBox);
        }

        if (marker) {
          newMarkers.push(marker);
        }
      }

      const newBoundingBoxesGroup = L.featureGroup(newBoundingBoxes);
      const newMarkerClusterGroup = L.markerClusterGroup({
        iconCreateFunction: (cluster) => createClusterIcon(cluster, randomColor),
      }).addLayers(newMarkers);

      const newResultLayer = L.layerGroup([newBoundingBoxesGroup, newMarkerClusterGroup]).addTo(
        mapRef.current
      );

      if (newMarkerClusterGroup.getLayers().length > 0) {
        // Delete the oldest result layer to make room for the new one
        if (comparisonResultsLayers.current.length >= MAX_RESULT_LAYER_STACK) {
          const layerIndex = comparisonResultsLayers.current.length - MAX_RESULT_LAYER_STACK;
          const layerToRemove = comparisonResultsLayers.current[layerIndex];
          comparisonResultsLayerControlRef.current.removeLayer(layerToRemove);
          layerToRemove.remove();
        }

        // Zoom into results
        mapRef.current.fitBounds(newMarkerClusterGroup.getBounds());

        // Get the most recent search query
        // NOTE: This will break if the app starts without a single QueryHistoryItem
        const queryText = document.querySelector('#query-history-item').title;

        // Store toggleable result layer
        comparisonResultsLayerControlRef.current.addOverlay(
          newResultLayer,
          `<span class="${randomColor.legendTextClassName}">${queryText}</span>`
        );

        comparisonResultsLayers.current.push(newResultLayer);
      }

      // Hide the control if there's nothing to display (and viceversa)
      comparisonResultsLayerControlRef.current.getContainer().style.display =
        comparisonResultsLayers.current.length === 0 ? 'none' : 'block';
    },
    [onBoundingBoxClick]
  );

  const onSliderValueChange = useCallback(
    (newSliderValue) => {
      if (compareMode) {
        const lastIndex = Math.min(comparisonResultsLayers.current.length, MAX_RESULT_LAYER_STACK);

        for (let i = 0; i < lastIndex; i++) {
          const layerIndex =
            Math.max(comparisonResultsLayers.current.length - MAX_RESULT_LAYER_STACK, 0) + i;

          // Erase all the results and start all over again
          const layer = comparisonResultsLayers.current[layerIndex];
          layer.clearLayers();

          // Get layer-specific data
          const layerColor = usedColors.current[i];
          const batch =
            comparisonResultsQueue.current[comparisonResultsQueue.current.length - 1 - i];

          const newBoundingBoxes = [];
          const newMarkers = [];

          // Create bbox and markers
          for (let k = 0; k < Math.min(newSliderValue, batch.length); k++) {
            const [imageObject] = batch[k];

            const { boundingBox, marker } = createMarkersAndBoundingBoxes({
              imageObject,
              onBoundingBoxClick,
              color: layerColor,
            });

            if (boundingBox) {
              newBoundingBoxes.push(boundingBox);
            }

            if (marker) {
              newMarkers.push(marker);
            }
          }

          // Add the newly truncated results
          const newBoundingBoxesGroup = L.featureGroup(newBoundingBoxes);
          const newMarkerClusterGroup = L.markerClusterGroup({
            iconCreateFunction: (cluster) => createClusterIcon(cluster, layerColor),
          }).addLayers(newMarkers);

          layer.addLayer(L.layerGroup([newBoundingBoxesGroup, newMarkerClusterGroup]));

          // Zoom into the most recent results,
          // if there isn't a specified geoboundary.
          if (
            newMarkerClusterGroup.getLayers().length > 0 &&
            i === lastIndex - 1 &&
            geoboundaryRef.current === null
          ) {
            mapRef.current.fitBounds(newMarkerClusterGroup.getBounds());
          }
        }
      } else {
        const lastIndex = Math.min(individualResultsLayers.current.length, MAX_RESULT_LAYER_STACK);

        for (let i = 0; i < lastIndex; i++) {
          const layerIndex =
            Math.max(individualResultsLayers.current.length - MAX_RESULT_LAYER_STACK, 0) + i;

          // Erase all the results and start all over again (from oldest to newest)
          const layer = individualResultsLayers.current[layerIndex];
          layer.clearLayers();

          // Get layer-specific data
          const layerColor = usedColors.current[i];
          const batch =
            individualResultsQueue.current[individualResultsQueue.current.length - 1 - i];

          const newBoundingBoxes = [];
          const newMarkers = [];

          // Create bbox and markers
          for (let k = 0; k < Math.min(newSliderValue, batch.length); k++) {
            const imageObject = batch[k];

            const { boundingBox, marker } = createMarkersAndBoundingBoxes({
              imageObject,
              onBoundingBoxClick,
              color: layerColor,
            });

            if (boundingBox) {
              newBoundingBoxes.push(boundingBox);
            }

            if (marker) {
              newMarkers.push(marker);
            }
          }

          // Add the newly truncated results
          const newBoundingBoxesGroup = L.featureGroup(newBoundingBoxes);
          const newMarkerClusterGroup = L.markerClusterGroup({
            iconCreateFunction: (cluster) => createClusterIcon(cluster, layerColor),
          }).addLayers(newMarkers);

          layer.addLayer(L.layerGroup([newBoundingBoxesGroup, newMarkerClusterGroup]));

          // Zoom into the most recent results,
          // if there isn't a specified geoboundary.
          if (
            newMarkerClusterGroup.getLayers().length > 0 &&
            i === lastIndex - 1 &&
            geoboundaryRef.current === null
          ) {
            mapRef.current.fitBounds(newMarkerClusterGroup.getBounds());
          }
        }
      }
    },
    [compareMode, onBoundingBoxClick]
  );

  const handleOnNextImageGalleryPageClick = useCallback(() => {
    const globalArray = (
      compareMode ? comparisonResultsQueue : individualResultsQueue
    ).current.flat();

    const resultsCount = globalArray.length;
    const lastPageIndex = Math.max(Math.floor((resultsCount - 1) / IMAGE_GALLERY_PAGE_SIZE), 0);
    const newPageIndex = Math.min(pageIndex + 1, lastPageIndex);

    setPageIndex(newPageIndex);

    if (compareMode) {
      setComparisonPageData(
        globalArray.slice(
          newPageIndex * IMAGE_GALLERY_PAGE_SIZE,
          (newPageIndex + 1) * IMAGE_GALLERY_PAGE_SIZE
        )
      );
    } else {
      setIndividualPageData(
        globalArray.slice(
          newPageIndex * IMAGE_GALLERY_PAGE_SIZE,
          (newPageIndex + 1) * IMAGE_GALLERY_PAGE_SIZE
        )
      );
    }

    // Scroll to top of container after clicking on pagination
    document.getElementById('image-gallery-container').scrollTop = 0;
  }, [pageIndex, compareMode]);

  const handleOnPreviousImageGalleryPageClick = useCallback(() => {
    const newPageIndex = Math.max(pageIndex - 1, 0);

    setPageIndex(newPageIndex);

    if (compareMode) {
      const globalArray = comparisonResultsQueue.current.flat();

      setComparisonPageData(
        globalArray.slice(
          newPageIndex * IMAGE_GALLERY_PAGE_SIZE,
          (newPageIndex + 1) * IMAGE_GALLERY_PAGE_SIZE
        )
      );
    } else {
      const globalArray = individualResultsQueue.current.flat();

      setIndividualPageData(
        globalArray.slice(
          newPageIndex * IMAGE_GALLERY_PAGE_SIZE,
          (newPageIndex + 1) * IMAGE_GALLERY_PAGE_SIZE
        )
      );
    }

    // Scroll to top of container after clicking on pagination
    document.getElementById('image-gallery-container').scrollTop = 0;
  }, [pageIndex, compareMode]);

  const handleResetResults = () => {
    // Clear Report Card
    setScoreQueue([]);

    // Clear the ImageGallery data
    setPageIndex(0);
    setIndividualPageData([]);
    setComparisonPageData([]);

    // Prompt the user to query
    setHasUserQueried(false);

    // Clear both result queues
    individualResultsQueue.current = [];
    comparisonResultsQueue.current = [];

    // Erase all individual plotted results
    individualResultsLayers.current.forEach((resultLayer) => {
      individualResultsLayerControlRef.current.removeLayer(resultLayer);
      resultLayer.remove();
    });

    individualResultsLayers.current = [];

    // Erase all comparison plotted results
    comparisonResultsLayers.current.forEach((resultLayer) => {
      comparisonResultsLayerControlRef.current.removeLayer(resultLayer);
      resultLayer.remove();
    });

    comparisonResultsLayers.current = [];

    // Reset the colors that can be used for the legend
    usedColors.current = [];

    // Hide the controls (since they won't be displaying anything)
    individualResultsLayerControlRef.current.getContainer().style.display = 'none';
    comparisonResultsLayerControlRef.current.getContainer().style.display = 'none';
  };

  const handlePlotMapOverlays = (newDataSource) => {
    // Clear the additional base layers
    additionalBaseLayersRef.current.forEach((additionalBaseLayer) => {
      baseLayerControlRef.current.removeLayer(additionalBaseLayer);
    });

    additionalBaseLayersRef.current = [];

    // Get the layers for the current dataSource
    const additionalBaseLayersForDataSource = getLayersForDataSource(newDataSource);

    // Add the new layer control based on dataSource
    Object.entries(additionalBaseLayersForDataSource).forEach(([layerName, layer]) => {
      baseLayerControlRef.current.addBaseLayer(layer, layerName);

      // Keep track of the added layers in order to remove them later
      additionalBaseLayersRef.current.push(layer);
    });

    // Hide the control if there's nothing to display (and viceversa)
    baseLayerControlRef.current.getContainer().style.display =
      additionalBaseLayersRef.current.length === 0 ? 'none' : 'block';
  };

  const startResizing = useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        setSidebarWidth(
          imageGalleryRef.current.getBoundingClientRect().right - mouseMoveEvent.clientX
        );
      }
    },
    [isResizing]
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  // On startup, fetch and store the username for future use
  useEffect(() => {
    const fetch = async () => {
      const { username } = await Auth.currentAuthenticatedUser();
      localStorage.setItem(USERNAME_LOCALSTORAGE_KEY, username);
    };

    fetch();
  }, []);

  return (
    <div className="relative flex flex-row">
      <CategorizationModal
        isOpen={isCategorizationModalOpen}
        activeCategoryIndex={activeClassificationCategoryIndex}
        onDeleteCategory={handleDeleteCategory}
        onEditCategoryName={handleOnCategoryNameChange}
        onRemoveImageObject={handleRemoveImageObjectFromCategory}
        categories={classificationCategories}
        onNext={handleOnCategorizationModalNext}
        onPrev={handleOnCategorizationModalPrev}
        onCommit={handleOnCategorizationModalCommit}
        isCommiting={isClassificationLoading}
      />
      <aside className="h-[100dvh] w-64">
        <SideBar
          signOut={signOut}
          onNewImageObjects={handleNewImageObjectResponse}
          onNewComparisonObjects={handleNewComparisonObjectResponse}
          compareMode={compareMode}
          setCompareMode={setCompareMode}
          primaryDataSource={primaryDataSource}
          setPrimaryDataSource={setPrimaryDataSource}
          setHasUserQueried={setHasUserQueried}
          setIsQueryLoading={setIsQueryLoading}
          isQueryLoading={isQueryLoading}
          geoboundaryRef={geoboundaryRef}
          mapRef={mapRef}
          drawnItemsRef={drawnItemsRef}
          individualResultsQueue={individualResultsQueue}
          comparisonResultsQueue={comparisonResultsQueue}
          setIndividualPageData={setIndividualPageData}
          setComparisonPageData={setComparisonPageData}
          setPageIndex={setPageIndex}
          onResetResults={handleResetResults}
          onPlotMapOverlays={handlePlotMapOverlays}
          setScoreQueue={setScoreQueue}
          geojsonLink={geojsonLink}
          geocodedLocation={geocodedLocation}
          setReportCardMode={setReportCardMode}
        />
      </aside>
      <div className="flex w-full flex-row">
        <Map
          setGeocodedLocation={setGeocodedLocation}
          onSliderValueChange={onSliderValueChange}
          mapRef={mapRef}
          drawnItemsRef={drawnItemsRef}
          geoboundaryRef={geoboundaryRef}
          baseLayerControlRef={baseLayerControlRef}
          individualResultsLayerControlRef={individualResultsLayerControlRef}
          comparisonResultsLayerControlRef={comparisonResultsLayerControlRef}
          classificationResultsLayerControlRef={classificationResultsLayerControlRef}
          sliderControlRef={sliderControlRef}
          setScoreQueue={setScoreQueue}
        />
        <div
          className={classNames(
            'basis-1 cursor-col-resize resize-x bg-slate-400 hover:basis-3',
            isResizing ? 'basis-3' : 'basis-1'
          )}
          onMouseDown={startResizing}
        />
        <div
          className="h-[100dvh] px-3 py-3 dark:bg-slate-900 dark:text-slate-200"
          style={{ flexBasis: sidebarWidth, maxWidth: '50%', minWidth: '17rem' }}
          ref={imageGalleryRef}
        >
          {isReportCardShowing && (
            <div className="h-1/2 overflow-y-auto overflow-x-hidden">
              <hr />
              <ReportCard className="mt-2" scores={scoreQueue} mode={reportCardMode} />
            </div>
          )}
          <ImageGallery
            className={isReportCardShowing ? 'h-1/2' : 'h-full'}
            individualPageData={individualPageData}
            comparisonPageData={comparisonPageData}
            onPrevPage={handleOnPreviousImageGalleryPageClick}
            onNextPage={handleOnNextImageGalleryPageClick}
            compareMode={compareMode}
            hasUserQueried={hasUserQueried}
            isQueryLoading={isQueryLoading}
            pageIndex={pageIndex}
            geocodedLocation={geocodedLocation}
            onAddImage={handleAddSelectedImage}
            selectedImageIds={selectedImageIds}
            isClassificationLoading={isClassificationLoading}
            mapRef={mapRef}
            individualResultsQueue={individualResultsQueue}
            comparisonResultsQueue={comparisonResultsQueue}
          />
        </div>
      </div>
    </div>
  );
};

App.propTypes = {
  signOut: PropTypes.func.isRequired,
};

export default withCustomAuthenticator(App);
