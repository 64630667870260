export const USERNAME_LOCALSTORAGE_KEY = 'TeraLensCurrentUserUsername';

export const API_RESULTS_LIMIT = 1000;

export const DEFAULT_CENTROID = [15.994402, -51.760254];

export const DEFAULT_ZOOM = 3;

export const DEFAULT_IMAGE_GALLERY_WIDTH_IN_PX = 268;

export const DATA_SOURCES = {
  CAR_ACCIDENTS: 'car_accidents',
  HOUSE_DAMAGE: 'house_damage',
  FEMA: 'ladi_FEMA_CAP',
  FL_2020: 'FL_2020',
  FL_2021: 'FL_2021',
  FL_2022: 'FL_2022',
  FL_2023: 'FL_2023',
  MAUI_AUGUST_2023: 'maui_wildfires_august_2023',
  SNOCO_2020: 'WA_2020',
  SNOCO_2021: 'WA_2021',
  SNOCO_2022: 'WA_2022',
  ALL_DATASETS: 'all',
  FEMA_CAP_ALL: 'FEMA_CAP_all',
  FL_PINELLAS_MAPILLARY: 'FL_pinellas_mapillary',
};

export const REGIONS = {
  FL: 1,
  HI_MAUI: 2,
  WA_SNOHOMISH: 3,
};

export const DEFAULT_DATA_SOURCE = DATA_SOURCES.ALL_DATASETS;

export const DATASET_DESCRIPTIONS = {
  [DATA_SOURCES.CAR_ACCIDENTS]: 'Car Accidents',
  [DATA_SOURCES.HOUSE_DAMAGE]: 'House Damage',
  [DATA_SOURCES.FEMA]: 'Demo (Aggregate Civil Air Patrol)',
  [DATA_SOURCES.FL_2020]: 'Florida Orthophotos 2020',
  [DATA_SOURCES.FL_2021]: 'Florida Orthophotos 2021',
  [DATA_SOURCES.FL_2022]: 'Florida Orthophotos 2022',
  [DATA_SOURCES.FL_2023]: 'Florida Orthophotos 2023',
  [DATA_SOURCES.MAUI_AUGUST_2023]: 'Maui Fire 2023 (Civil Air Patrol)',
  [DATA_SOURCES.SNOCO_2020]: 'Snohomish County Orthophotos 2020',
  [DATA_SOURCES.SNOCO_2021]: 'Snohomish County Orthophotos 2021',
  [DATA_SOURCES.SNOCO_2022]: 'Snohomish County Orthophotos 2022',
  [DATA_SOURCES.ALL_DATASETS]: 'All data',
  [DATA_SOURCES.FEMA_CAP_ALL]: 'FEMA Civil Air Patrol',
  [DATA_SOURCES.FL_PINELLAS_MAPILLARY]: 'Pinellas County Street View',
};

export const FL_DATASETS = [
  DATA_SOURCES.FL_2020,
  DATA_SOURCES.FL_2021,
  DATA_SOURCES.FL_2022,
  DATA_SOURCES.FL_2023,
  DATA_SOURCES.FL_PINELLAS_MAPILLARY,
];

export const WA_DATASETS = [
  DATA_SOURCES.SNOCO_2020,
  DATA_SOURCES.SNOCO_2021,
  DATA_SOURCES.SNOCO_2022,
];

export const REGION_TO_COORDINATES = {
  [REGIONS.FL]: [27.6648, -81.5158],
  [REGIONS.HI_MAUI]: [20.798363, -156.331924],
  [REGIONS.WA_SNOHOMISH]: [47.918162994, -122.087999648],
};

export const DEFAULT_QUERY_HISTORY = [
  'Jets',
  'Flooded houses',
  'Houses at risk of collapse',
  'Homes with tarp',
  'Poorly maintained homes',
  'New construction',
  'Wildfire risk',
  'Flood risk',
  'Industrial zones',
  'Homes with tree overhangs',
  'Cars submerged in floodwater',
  'Homes near burn scars',
  'Flooded critical infrastructure',
  'Streetview',
  'Aerial',
];

export const COMPARABLE_DATASETS = [
  DATA_SOURCES.FL_2020,
  DATA_SOURCES.FL_2021,
  DATA_SOURCES.FL_2022,
  DATA_SOURCES.FL_2023,
  DATA_SOURCES.SNOCO_2020,
  DATA_SOURCES.SNOCO_2021,
  DATA_SOURCES.SNOCO_2022,
];

export const COMPATIBLE_DATASETS = {
  [DATA_SOURCES.CAR_ACCIDENTS]: [],
  [DATA_SOURCES.HOUSE_DAMAGE]: [],
  [DATA_SOURCES.FEMA]: [],
  [DATA_SOURCES.FL_2020]: [DATA_SOURCES.FL_2021, DATA_SOURCES.FL_2022, DATA_SOURCES.FL_2023],
  [DATA_SOURCES.FL_2021]: [DATA_SOURCES.FL_2020, DATA_SOURCES.FL_2022, DATA_SOURCES.FL_2023],
  [DATA_SOURCES.FL_2022]: [DATA_SOURCES.FL_2020, DATA_SOURCES.FL_2021, DATA_SOURCES.FL_2023],
  [DATA_SOURCES.FL_2023]: [DATA_SOURCES.FL_2020, DATA_SOURCES.FL_2021, DATA_SOURCES.FL_2022],
  [DATA_SOURCES.MAUI_AUGUST_2023]: [],
  [DATA_SOURCES.SNOCO_2020]: [DATA_SOURCES.SNOCO_2021, DATA_SOURCES.SNOCO_2022],
  [DATA_SOURCES.SNOCO_2021]: [DATA_SOURCES.SNOCO_2020, DATA_SOURCES.SNOCO_2022],
  [DATA_SOURCES.SNOCO_2022]: [DATA_SOURCES.SNOCO_2020, DATA_SOURCES.SNOCO_2021],
  [DATA_SOURCES.ALL_DATASETS]: [],
  [DATA_SOURCES.FEMA_CAP_ALL]: [],
  [DATA_SOURCES.FL_PINELLAS_MAPILLARY]: [],
};

export const SEARCH_OPTIONS = [
  'Infrastructure',
  'Flooded infrastructure',
  'Flooded infrastructure near houses',
];

export const DEFAULT_SECONDARY_DATA_SOURCE = 'none';

export const IMAGE_SEARCH_FILE_SIZE_LIMIT_IN_BYTES = 5 * 1024 * 1024;

// Random UUID used to uniquely identify QueryHistoryItems that represent an Image Upload
export const IMAGE_SEARCH_QUERY_TEXT = '54574d77-046d-4575-8fc4-d2fd8ef05411';

export const IMAGE_SEARCH_DISPLAY_TEXT = 'Image was uploaded';

// Configuration for the ArcGIS mosaic servers
export const DATASETS_IMAGE_LAYER_CONFIG = {
  snoco: {
    2020: 'https://maps.snoco.org/gisimagery/rest/services/imagery/2020_orthophotos/ImageServer',
    2021: 'https://maps.snoco.org/gisimagery/rest/services/imagery/2021_orthophotos/ImageServer',
    2022: 'https://maps.snoco.org/gisimagery/rest/services/imagery/2022_orthophotos/ImageServer',
  },
  collier: {
    2020: 'https://ags2.colliercountyfl.gov/imagery/rest/services/Orthos2020/ImageServer',
    2021: 'https://ags2.colliercountyfl.gov/imagery/rest/services/Orthos2021/ImageServer',
    2022: 'https://ags2.colliercountyfl.gov/imagery/rest/services/Orthos2022/ImageServer',
  },
  pinellas: {
    2020: 'https://egis.pinellas.gov/gis/rest/services/Aerials/Aerials2020/ImageServer',
    2021: 'https://egis.pinellas.gov/gis/rest/services/Aerials/Aerials2021/ImageServer',
    2022: 'https://egis.pinellas.gov/gis/rest/services/Aerials/Aerials2022/ImageServer',
    2023: 'https://egis.pinellas.gov/gis/rest/services/Aerials/Aerials2023/ImageServer',
  },
  broward: {
    2020: 'https://bcgishub.broward.org/image/rest/services/Imagery/Aerials2020/ImageServer',
    2021: 'https://bcgishub.broward.org/image/rest/services/Imagery/Aerials2021/ImageServer',
    2022: 'https://bcgishub.broward.org/image/rest/services/Imagery/Aerials2022Web/ImageServer',
    2023: 'https://bcgishub.broward.org/image/rest/services/Imagery/Aerials2023/ImageServer',
  },
  sarasota: {
    2020: 'https://ags3.scgov.net/agsfed/rest/services/ImageServices/SC2020/ImageServer',
    2021: 'https://ags3.scgov.net/agsfed/rest/services/ImageServices/SC2021/ImageServer',
    2022: 'https://ags3.scgov.net/agsfed/rest/services/ImageServices/SC2022/ImageServer',
    2023: 'https://ags3.scgov.net/agsfed/rest/services/ImageServices/SC2023/ImageServer',
  },
  marion: {
    2020: 'https://gis.ocalafl.org/arcgis/rest/services/Aerial2020SID/ImageServer/',
  },
  palmbeach: {
    2021: 'https://maps.co.palm-beach.fl.us/arcgis/rest/services/Aerials/2021/MapServer',
    2022: 'https://maps.co.palm-beach.fl.us/arcgis/rest/services/Aerials/2022/MapServer',
  },
};

export const IMAGE_GALLERY_PAGE_SIZE = 100;

export const MAX_RESULT_LAYER_STACK = 5;

// See more here: https://tailwindcss.com/docs/customizing-colors
export const RESULT_LAYER_COLOR_CLASSNAME_POOL = [
  {
    rawColorHex: '#e11d48',
    legendTextClassName: 'text-rose-600',
    imageMarkerClassName: 'border-rose-600',
    clusterClassName: 'bg-rose-600 hover:bg-rose-700',
  },
  {
    rawColorHex: '#c026d3',
    legendTextClassName: 'text-fuchsia-600',
    imageMarkerClassName: 'border-fuchsia-600',
    clusterClassName: 'bg-fuchsia-600 hover:bg-fuchsia-700',
  },
  {
    rawColorHex: '#4f46e5',
    legendTextClassName: 'text-indigo-600',
    imageMarkerClassName: 'border-indigo-600',
    clusterClassName: 'bg-indigo-600 hover:bg-indigo-700',
  },
  {
    rawColorHex: '#0284c7',
    legendTextClassName: 'text-sky-600',
    imageMarkerClassName: 'border-sky-600',
    clusterClassName: 'bg-sky-600 hover:bg-sky-700',
  },
  {
    rawColorHex: '#0d9488',
    legendTextClassName: 'text-teal-600',
    imageMarkerClassName: 'border-teal-600',
    clusterClassName: 'bg-teal-600 hover:bg-teal-700',
  },
  {
    rawColorHex: '#d97706',
    legendTextClassName: 'text-amber-600',
    imageMarkerClassName: 'border-amber-600',
    clusterClassName: 'bg-amber-600 hover:bg-amber-700',
  },
];

export const INITIAL_RESULTS_SHOWN_PER_LAYER = 100;

export const MILE_IN_METERS = 1609.34;

export const CLASSIFICATION_LAYER_COLORS = [
  '#FF0000', // Red
  '#0000FF', // Blue
  '#008000', // Green
  '#FFFF00', // Yellow
  '#800080', // Purple
  '#34495E', // Navy Blue
  '#E74C3C', // Red Orange
  '#2ECC71', // Mint
  '#3498DB', // Light Blue
  '#7F8C8D', // Grey
  '#1ABC9C', // Turquoise
  '#F39C12', // Orange
  '#D35400', // Pumpkin
  '#C0392B', // Dark Red
  '#8E44AD', // Dark Purple
  '#BDC3C7', // Silver
  '#16A085', // Sea Green
  '#27AE60', // Nephritis
  '#2980B9', // Belize Hole Blue
  '#2C3E50', // Midnight Blue
];

export const IMAGERY_TYPES = {
  VERTICAL_AERIAL: 'aerial',
  STREET_VIEW: 'streetview',
  OBLIQUE_AERIAL: 'oblique',
  OTHER: 'other',
};

export const DATASET_TO_IMAGERY_TYPE = {
  [DATA_SOURCES.CAR_ACCIDENTS]: IMAGERY_TYPES.OTHER,
  [DATA_SOURCES.HOUSE_DAMAGE]: IMAGERY_TYPES.OTHER,
  [DATA_SOURCES.FEMA]: IMAGERY_TYPES.OBLIQUE_AERIAL,
  [DATA_SOURCES.FL_2020]: IMAGERY_TYPES.VERTICAL_AERIAL,
  [DATA_SOURCES.FL_2021]: IMAGERY_TYPES.VERTICAL_AERIAL,
  [DATA_SOURCES.FL_2022]: IMAGERY_TYPES.VERTICAL_AERIAL,
  [DATA_SOURCES.FL_2023]: IMAGERY_TYPES.VERTICAL_AERIAL,
  [DATA_SOURCES.MAUI_AUGUST_2023]: IMAGERY_TYPES.OBLIQUE_AERIAL,
  [DATA_SOURCES.SNOCO_2020]: IMAGERY_TYPES.VERTICAL_AERIAL,
  [DATA_SOURCES.SNOCO_2021]: IMAGERY_TYPES.VERTICAL_AERIAL,
  [DATA_SOURCES.SNOCO_2022]: IMAGERY_TYPES.VERTICAL_AERIAL,
  [DATA_SOURCES.FEMA_CAP_ALL]: IMAGERY_TYPES.OBLIQUE_AERIAL,
  [DATA_SOURCES.FL_PINELLAS_MAPILLARY]: IMAGERY_TYPES.STREET_VIEW,
};

export const SKIP_DATASETS = [DATA_SOURCES.FL_PINELLAS_MAPILLARY];
