import React, { useRef, useState } from 'react';

import classNames from 'classnames';
import { ChevronDown, ChevronUp, Loader2, Trash, X } from 'lucide-react';
import PropTypes from 'prop-types';

const CategoryBundleImage = ({ imageObject, onClick, disabled }) => {
  const handleClick = () => {
    onClick(imageObject.id);
  };

  return (
    <button
      className="group relative mr-2 disabled:cursor-not-allowed"
      onClick={handleClick}
      disabled={disabled}
      title="Remove image from category"
    >
      <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-black text-white opacity-0 transition-opacity group-hover:opacity-100">
        <X size={24} />
      </div>
      <img
        loading="lazy"
        className="image-marker-animation h-12 w-12 rounded-md object-cover"
        src={imageObject.url}
        alt={imageObject.url}
      />
    </button>
  );
};

CategoryBundleImage.propTypes = {
  disabled: PropTypes.bool.isRequired,
  imageObject: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

const CategoryBundle = ({
  index,
  category,
  disabled,
  onEditCategoryName,
  onRemoveImageObject,
  onDeleteCategory,
}) => {
  const inputRef = useRef(null);
  const [text, setText] = useState(category.name);

  const placeholder = `Category ${index + 1}`;

  const handleOnCategoryNameChange = (event) => {
    setText(event.target.value);
  };

  const handleOnEnterPressed = (event) => {
    if (event.key === 'Enter') {
      if (inputRef.current) {
        inputRef.current.blur();
      } else {
        console.error('Attempted blurring an input that does not exist!');
      }
    }
  };

  const handleOnBlur = () => {
    onEditCategoryName(category.id, text);
  };

  const handleRemoveImageObject = (imageObjectId) => {
    onRemoveImageObject(category.id, imageObjectId);
  };

  const handleDeleteCategory = () => {
    onDeleteCategory(category.id);
  };

  return (
    // TODO: somehow accentuate non-disabled bundles!
    <div className={classNames('mb-2', disabled ? '' : '')}>
      <div className="mb-1 flex flex-row items-center justify-between">
        <input
          className="w-full rounded-md bg-transparent px-1 transition-colors hover:bg-slate-100 focus:bg-slate-200 disabled:cursor-not-allowed dark:hover:bg-slate-950 dark:focus:bg-slate-800"
          type="text"
          placeholder={placeholder}
          value={text}
          onChange={handleOnCategoryNameChange}
          onKeyDown={handleOnEnterPressed}
          onBlur={handleOnBlur}
          ref={inputRef}
          disabled={disabled}
        />
        <button
          className="rounded-md p-1 hover:bg-slate-100 disabled:cursor-not-allowed dark:hover:bg-slate-950"
          type="button"
          onClick={handleDeleteCategory}
          disabled={disabled}
          title="Delete category"
        >
          <Trash size={16} />
        </button>
      </div>
      {/* // TODO: scroll horizontally */}
      {/* NOTE: padding of 8px and width of 320px */}
      {category.imageObjects.length > 0 ? (
        <div className="overflow-x-scroll">
          {category.imageObjects.map((imageObject) => (
            <CategoryBundleImage
              key={imageObject.id}
              imageObject={imageObject}
              onClick={handleRemoveImageObject}
              disabled={disabled}
            />
          ))}
        </div>
      ) : (
        <div className="rounded-md bg-slate-100 px-4 py-2 text-xs dark:bg-slate-700">
          <h4 className="font-bold">Empty</h4>
          <p className="text-slate-400 dark:text-slate-300">
            Add images by clicking &apos;+&apos; on the right
          </p>
        </div>
      )}
    </div>
  );
};

CategoryBundle.propTypes = {
  category: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  onEditCategoryName: PropTypes.func.isRequired,
  onRemoveImageObject: PropTypes.func.isRequired,
  onDeleteCategory: PropTypes.func.isRequired,
};

const CategorizationModal = ({
  isOpen,
  categories,
  activeCategoryIndex,
  isCommiting,
  onNext,
  onPrev,
  onEditCategoryName,
  onRemoveImageObject,
  onDeleteCategory,
  onCommit,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // TODO: disable next button when the last category doesn't have a name nor imageObjects
  const isPrevDisabled = activeCategoryIndex === 0 || isCommiting;
  const isNextDisabled = isCommiting;
  const isCommitingDisabled = categories.length < 2 || isCommiting;

  const handleExpansionToggle = () => {
    setIsExpanded((previous) => !previous);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="absolute bottom-0 left-0 z-50 mb-4 ml-4">
      <span
        className={classNames(
          'absolute -right-2 -top-2 h-4 w-4 animate-ping rounded-full bg-red-600',
          isExpanded ? 'hidden' : 'inline'
        )}
      />
      <div
        className={classNames(
          'w-80 overflow-y-scroll rounded-md border border-slate-300 bg-slate-50 px-2 py-2 text-slate-700 shadow-md dark:border-slate-700 dark:bg-slate-900 dark:text-slate-200',
          isExpanded ? 'h-80' : ''
        )}
      >
        <div
          className={classNames(
            'flex flex-row items-center justify-between',
            isExpanded ? 'mb-2' : 'mb-0'
          )}
        >
          <h3 className="text-lg font-bold">Real-Time Classifier</h3>
          <button
            className="rounded-full p-2 transition-colors hover:bg-slate-200 dark:hover:bg-slate-950"
            type="button"
            onClick={handleExpansionToggle}
          >
            {isExpanded ? <ChevronDown size={16} /> : <ChevronUp size={16} />}
          </button>
        </div>
        <div className={classNames('flex flex-col', isExpanded ? 'block' : 'hidden')}>
          <div>
            {categories.map((category, i) => (
              <CategoryBundle
                key={category.id}
                index={i}
                disabled={i !== activeCategoryIndex || isCommiting}
                category={category}
                onEditCategoryName={onEditCategoryName}
                onRemoveImageObject={onRemoveImageObject}
                onDeleteCategory={onDeleteCategory}
              />
            ))}
          </div>
          <div className="ml-auto flex flex-row items-center">
            <button
              className="mr-2 rounded-md bg-slate-100 px-1 py-0.5 text-sm transition-colors hover:bg-slate-200 disabled:cursor-not-allowed disabled:bg-slate-300 dark:bg-slate-800 dark:hover:bg-slate-950 dark:disabled:bg-slate-500"
              disabled={isPrevDisabled}
              onClick={onPrev}
            >
              Prev
            </button>
            <button
              className="mr-2 rounded-md bg-slate-100 px-1 py-0.5 text-sm transition-colors hover:bg-slate-200 disabled:bg-slate-300 dark:bg-slate-800 dark:hover:bg-slate-950 dark:disabled:bg-slate-500"
              disabled={isNextDisabled}
              onClick={onNext}
            >
              Next
            </button>
            <button
              className="rounded-md bg-slate-100 px-1 py-0.5 text-sm transition-colors hover:bg-slate-200 disabled:cursor-not-allowed disabled:bg-slate-300 dark:bg-slate-800 dark:hover:bg-slate-950 dark:disabled:bg-slate-500"
              disabled={isCommitingDisabled}
              onClick={onCommit}
            >
              {isCommiting ? <Loader2 size={20} className="animate-spin" /> : <span>Go</span>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

CategorizationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeCategoryIndex: PropTypes.number.isRequired,
  isCommiting: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onEditCategoryName: PropTypes.func.isRequired,
  onRemoveImageObject: PropTypes.func.isRequired,
  onDeleteCategory: PropTypes.func.isRequired,
  onCommit: PropTypes.func.isRequired,
};

export default CategorizationModal;
