import React, { useRef } from 'react';

import { X } from 'lucide-react';
import PropTypes from 'prop-types';

const ImagePopup = ({ src, onClose }) => {
  const imageContainerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (imageContainerRef.current && !imageContainerRef.current.contains(event.target)) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex flex-row items-center justify-center bg-black bg-opacity-50"
      onClick={handleClickOutside}
    >
      <div
        className="popup-container relative flex min-h-[512px] min-w-[512px] flex-row items-center justify-center rounded-md bg-slate-100 dark:bg-gray-600"
        ref={imageContainerRef}
      >
        <span className="loadingDots absolute top-1/2 z-0 -mt-4 w-full text-center text-5xl text-slate-400 dark:text-white" />
        <img
          src={src}
          className="z-10 max-h-[90vh] max-w-[90vw] rounded-md object-contain"
          alt="Popup"
          loading="lazy"
        />
        <div className="absolute right-2 top-2 z-10">
          <button
            onClick={onClose}
            className="rounded-full bg-white p-2 transition-colors hover:bg-slate-300"
          >
            <X size={20} className="text-slate-700" />
          </button>
        </div>
      </div>
    </div>
  );
};

ImagePopup.propTypes = {
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImagePopup;
