import { flushSync } from 'react-dom';

import turfBboxPolygon from '@turf/bbox-polygon';
import turfContains from '@turf/boolean-contains';
import turfBooleanPointInPolygon from '@turf/boolean-point-in-polygon';
import circle from '@turf/circle';
import turfDistance from '@turf/distance';
import { polygon as turfPolygon, point as turfPoint } from '@turf/helpers';
import { imageMapLayer, dynamicMapLayer } from 'esri-leaflet';
import L from 'leaflet';

import {
  REGIONS,
  DATA_SOURCES,
  DATASETS_IMAGE_LAYER_CONFIG,
  DATASET_TO_IMAGERY_TYPE,
  FL_DATASETS,
  WA_DATASETS,
  IMAGERY_TYPES,
} from './constants';

export const generateThumbnailUrlFromOriginalUrl = (originalUrl) => {
  const newUrl = new URL(originalUrl);

  // Resizing is only doable for SNOCO
  if (newUrl.host !== 'maps.snoco.org') {
    return newUrl.toString();
  }

  let newWidth;
  const sizeQueryParam = newUrl.searchParams.get('size');

  // Reduce original image size, defaulting to 512x512 if unknown
  // NOTE: This assumes the image is square
  // TODO: what about non-square images?
  if (sizeQueryParam) {
    const [width] = sizeQueryParam.split(',');
    newWidth = parseInt(width) / 16;
  } else {
    newWidth = 256;
  }

  newUrl.searchParams.set('size', `${newWidth},${newWidth}`);

  return newUrl.toString();
};

export const getBase64FromDataURL = (dataURL) => {
  if (!dataURL.startsWith('data:image/')) {
    throw new Error('Uploaded file is not an image.');
  }

  const commaIndex = dataURL.indexOf(',');
  const mimeType = dataURL.substring(5, commaIndex).split(';')[0];
  const base64Content = dataURL.substring(commaIndex + 1);

  return { mimeType, base64Content };
};

export const createMarkersAndBoundingBoxes = ({ imageObject, onBoundingBoxClick, color }) => {
  const [latitude, longitude] = imageObject.coordinates;

  if (latitude === null || longitude === null) {
    return {
      boundingBox: null,
      marker: null,
    };
  }

  // Define the behaviour for clicks on map elements
  const onClick = () => {
    // Attempt changing the page being shown on the ImageGallery
    // NOTE: We force the DOM to update before the next lines
    flushSync(() => onBoundingBoxClick(imageObject.id));

    // Attempt highlighting the image in the new page
    const imageItemElement = document.getElementById(`image-item-${imageObject.id}`);

    if (imageItemElement === null) {
      console.error(`Couldn't find element to scroll to with ID: #image-item-${imageObject.id}`);
      return;
    }

    // Apply smooth scrolling (if image is in the same page)
    imageItemElement.scrollIntoView({
      behavior: 'smooth',
    });

    // Add a border to make it more obvious
    imageItemElement.classList.add('!border-blue-500');
    imageItemElement.classList.add('dark:!border-red-500');
    setTimeout(() => {
      imageItemElement.classList.remove('!border-blue-500');
      imageItemElement.classList.remove('dark:!border-red-500');
    }, 1500);
  };

  // If there's no bbox info,
  // we should return a marker with the url
  if (
    imageObject.xmin === null ||
    imageObject.xmax === null ||
    imageObject.ymin === null ||
    imageObject.ymax === null
  ) {
    const url = generateThumbnailUrlFromOriginalUrl(imageObject.url);
    return {
      boundingBox: null,
      marker: L.marker(imageObject.coordinates, {
        icon: L.divIcon({
          // NOTE: Use !important directive for the width to override .leaflet-container.leaflet-marker-pane styles
          html: `<img class="!w-24 h-24 object-cover rounded-full image-marker-animation" src="${url}" loading="lazy" />`,
          className: `border-2 rounded-full ${color.imageMarkerClassName}`,
          iconSize: [100, 100], // This should include border + img size in pixels
        }),
      }).addEventListener('click', onClick),
    };
  }

  // Create bbox with its event handling
  const boundingBox = L.rectangle(
    [
      [imageObject.ymin, imageObject.xmin],
      [imageObject.ymax, imageObject.xmax],
    ],
    {
      color: color.rawColorHex,
      weight: 1,
    }
  ).addEventListener('click', onClick);

  // Create regular marker so that it shows on sufficient zoom
  const marker = L.marker(imageObject.coordinates).addEventListener('click', onClick);

  return {
    boundingBox,
    marker,
  };
};

export const getRegionFromDataset = (dataset) => {
  if (dataset === DATA_SOURCES.MAUI_AUGUST_2023) {
    return REGIONS.HI_MAUI;
  }

  if (FL_DATASETS.includes(dataset)) {
    return REGIONS.FL;
  }

  if (WA_DATASETS.includes(dataset)) {
    return REGIONS.WA_SNOHOMISH;
  }

  // No region for detected dataset
  return null;
};

export const getLayersForDataSource = (source) => {
  const region = getRegionFromDataset(source);

  if (region === REGIONS.FL) {
    return {
      2020: L.layerGroup([
        imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.collier[2020] }),
        imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.pinellas[2020] }),
        imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.broward[2020] }),
        imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.sarasota[2020] }),
      ]),
      2021: L.layerGroup([
        imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.collier[2021] }),
        imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.pinellas[2021] }),
        imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.broward[2021] }),
        imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.sarasota[2021] }),
        dynamicMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.palmbeach[2021] }),
      ]),
      2022: L.layerGroup([
        imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.collier[2022] }),
        imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.pinellas[2022] }),
        imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.broward[2022] }),
        imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.sarasota[2022] }),
        dynamicMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.palmbeach[2022] }),
      ]),
      2023: L.layerGroup([
        imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.pinellas[2023] }),
        imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.broward[2023] }),
        imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.sarasota[2023] }),
      ]),
      None: L.layerGroup([]),
    };
  }

  if (region === REGIONS.WA_SNOHOMISH) {
    return {
      2020: imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.snoco[2020] }),
      2021: imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.snoco[2021] }),
      2022: imageMapLayer({ url: DATASETS_IMAGE_LAYER_CONFIG.snoco[2022] }),
      None: L.layerGroup([]),
    };
  }

  // If no region detected,
  // return early with None layer.
  return {};
};

const isImageObjectContainedByGeoboundary = (imageObject, geoboundary) => {
  // NOTE: This comes from LeafletJS and in the order TurfJS expects.
  let parentPolygon;
  if (geoboundary.geometry.type === 'Point' && geoboundary.properties.radius > 0) {
    const circlePolygon = circle(geoboundary.geometry.coordinates, geoboundary.properties.radius, {
      units: 'meters',
    });
    parentPolygon = turfPolygon(circlePolygon.geometry.coordinates);
  } else {
    parentPolygon = turfPolygon(geoboundary.geometry.coordinates);
  }

  // Use bounding box if available
  if (
    imageObject.xmin !== null &&
    imageObject.xmax !== null &&
    imageObject.ymin !== null &&
    imageObject.ymax !== null
  ) {
    const bbox = turfBboxPolygon([
      imageObject.xmin,
      imageObject.ymin,
      imageObject.xmax,
      imageObject.ymax,
    ]);
    return turfContains(parentPolygon, bbox);
  }

  const [latitude, longitude] = imageObject.coordinates;

  // Fallback to point geometry
  if (latitude !== null && longitude !== null) {
    const point = turfPoint([longitude, latitude]);
    return turfBooleanPointInPolygon(point, parentPolygon);
  }

  // Default to false
  return false;
};

export const filterComparisonObjectsByGeoboundary = (comparisonObjects, geoboundary) => {
  return comparisonObjects.filter(([imageObject]) =>
    isImageObjectContainedByGeoboundary(imageObject, geoboundary)
  );
};

export const filterImageObjectsByGeoboundary = (imageObjects, geoboundary) => {
  return imageObjects.filter((imageObject) =>
    isImageObjectContainedByGeoboundary(imageObject, geoboundary)
  );
};

export const generateDistanceString = (fromCoordinates, toCoordinates) => {
  const NUMBER_CUTOFF = 1000000;

  const distanceInFeet = turfDistance(fromCoordinates, toCoordinates, {
    units: 'feet',
  });

  // Display in feet
  if (distanceInFeet < NUMBER_CUTOFF) {
    return distanceInFeet.toLocaleString('en-US', {
      style: 'unit',
      unit: 'foot',
      maximumSignificantDigits: 6,
    });
  }

  const distanceInYards = turfDistance(fromCoordinates, toCoordinates, {
    units: 'yards',
  });

  // Display in yards
  if (distanceInYards < NUMBER_CUTOFF) {
    return distanceInYards.toLocaleString('en-US', {
      style: 'unit',
      unit: 'yard',
      maximumSignificantDigits: 6,
    });
  }

  const distanceInMiles = turfDistance(fromCoordinates, toCoordinates, {
    units: 'miles',
  });

  // Default to miles
  return distanceInMiles.toLocaleString('en-US', {
    style: 'unit',
    unit: 'mile',
    maximumSignificantDigits: 6,
  });
};

export const base64ToBlob = (base64, mimeType = '') => {
  const sliceSize = 1024;
  const byteChars = window.atob(base64);
  const byteArrays = [];

  for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
    const slice = byteChars.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: mimeType });
  return blob;
};

export const convertDMSToDD = (degrees, minutes, seconds, direction) => {
  let dd = degrees + minutes / 60 + seconds / (60 * 60);
  if (direction === 'S' || direction === 'W') {
    dd = dd * -1;
  }
  return dd;
};

export const createClusterIcon = (cluster, color) => {
  return L.divIcon({
    html:
      `<div class="${color.clusterClassName} transition-colors shadow-lg"><span>` +
      cluster.getChildCount() +
      '</span></div>',
    iconSize: L.point(40, 40),
    className: 'marker-cluster',
  });
};

export const intertwineLists = (listOfLists) => {
  let maxLength = 0;

  for (const list of listOfLists) {
    if (list.length > maxLength) {
      maxLength = list.length;
    }
  }

  const intertwinedList = [];
  for (let i = 0; i < maxLength; i++) {
    for (const list of listOfLists) {
      if (i < list.length) {
        intertwinedList.push(list[i]);
      }
    }
  }

  return intertwinedList;
};

export const getDatasets = (primaryDataSource, checkboxState = null) => {
  // If no checkbox state is provided, default to all imagery types
  if (checkboxState === null) {
    checkboxState = {
      [IMAGERY_TYPES.VERTICAL_AERIAL]: true,
      [IMAGERY_TYPES.STREET_VIEW]: true,
      [IMAGERY_TYPES.OBLIQUE_AERIAL]: true,
    };
  }

  if (primaryDataSource === DATA_SOURCES.ALL_DATASETS) {
    // Get all dataset keys except 'ALL_DATASETS'
    const datasetKeys = Object.keys(DATA_SOURCES).filter((key) => key !== 'ALL_DATASETS');

    // Filter datasets based on their imagery type and the state of corresponding checkboxes
    return datasetKeys
      .map((key) => DATA_SOURCES[key])
      .filter((dataset) => {
        const imageryType = DATASET_TO_IMAGERY_TYPE[dataset];
        return checkboxState[imageryType];
      });
  }

  if (
    FL_DATASETS.includes(primaryDataSource) &&
    primaryDataSource !== DATA_SOURCES.FL_PINELLAS_MAPILLARY
  ) {
    if (checkboxState[IMAGERY_TYPES.VERTICAL_AERIAL] && checkboxState[IMAGERY_TYPES.STREET_VIEW]) {
      return [primaryDataSource, DATA_SOURCES.FL_PINELLAS_MAPILLARY];
    } else if (checkboxState[IMAGERY_TYPES.VERTICAL_AERIAL]) {
      return [primaryDataSource];
    } else if (checkboxState[IMAGERY_TYPES.STREET_VIEW]) {
      return [DATA_SOURCES.FL_PINELLAS_MAPILLARY];
    } else {
      return [];
    }
  }

  return [primaryDataSource];
};

export const getLatLng = async (address) => {
  try {
    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
      address
    )}`;
    const response = await fetch(url);
    const data = await response.json();

    return data && data.length > 0
      ? {
          latitude: data[0].lat,
          longitude: data[0].lon,
        }
      : null;
  } catch (error) {
    console.error('Error fetching geocode:', error);
    return null;
  }
};
