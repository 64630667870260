import React from 'react';

import { Search } from 'lucide-react';

const PromptBanner = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <Search size={128} className="mb-8 text-slate-700 dark:text-slate-200" />
      <ol className="rounded-md border border-slate-300 bg-slate-100 px-2.5 py-2 text-slate-700 dark:border-slate-600 dark:bg-slate-700 dark:text-slate-200">
        <li>
          <code className="text-xs">1. Select dataset</code>
        </li>
        <li>
          <code className="text-xs">2. Type query | Upload image</code>
        </li>
        <li>
          <code className="text-xs">3. Submit</code>
        </li>
      </ol>
    </div>
  );
};

export default PromptBanner;
