import React, { useEffect, useCallback } from 'react';

import {
  Authenticator,
  useTheme,
  View,
  Text,
  Heading,
  useAuthenticator,
  Button,
} from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import jwtDecode from 'jwt-decode';

import TeraLensIcon from './components/TeraLensIcon';
import { USERNAME_LOCALSTORAGE_KEY } from './constants';

const withCustomAuthenticator = (WrappedComponent) => {
  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View
          padding={tokens.space.large}
          className="flex flex-col items-center justify-center text-center"
        >
          <View className="flex flex-row items-center">
            <TeraLensIcon className="mr-3 h-12 w-12" />
            <h2 className="text-5xl font-medium text-slate-800">
              <span className="font-bold">TeraLens</span>
            </h2>
          </View>
          <h2 className="mt-2 text-xl text-slate-600">
            A new way to interact with the world&apos;s imagery.
          </h2>
          <iframe
            width="479"
            height="334"
            src="https://www.youtube.com/embed/zHS0oT4i4oc?rel=0&vq=hd1080&autoplay=1"
            title="YouTube video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowfullscreen"
            className="mb-[10px] mt-[17px]"
          />
        </View>
      );
    },

    SignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Get Early Access
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
        return (
          <View className="mb-4 text-center">
            <Button className="font-normal" onClick={toSignIn} size="small" variation="link">
              Back to Sign In
            </Button>
          </View>
        );
      },
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Welcome Back
          </Heading>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View className="mb-4 text-center">
            <Button className="font-normal" onClick={toResetPassword} size="small" variation="link">
              Reset Password
            </Button>
          </View>
        );
      },
    },

    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        );
      },

      Footer() {
        return (
          <div className="relative mt-[-400px] flex h-[55vh] flex-row items-center justify-center bg-white">
            <Text>Thank you for registering for early access. We will be in touch soon.</Text>
          </div>
        );
      },
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
  };

  const formFields = {
    signUp: {
      'custom:FirstName': {
        label: 'First name',
        placeholder: '',
        isRequired: true,
        order: 1,
      },
      'custom:LastName': {
        label: 'Last Name',
        placeholder: '',
        isRequired: true,
        order: 2,
      },
      username: {
        label: 'Username',
        placeholder: '',
        isRequired: true,
        order: 3,
      },
      email: {
        label: 'Email',
        placeholder: '',
        isRequired: true,
        order: 4,
      },
      password: {
        label: 'Password',
        placeholder: '',
        isRequired: true,
        order: 5,
      },
      // eslint-disable-next-line camelcase
      confirm_password: {
        label: 'Confirm Password',
        placeholder: '',
        isRequired: true,
        order: 6,
      },
      'custom:DataTypes': {
        label: 'What type(s) of image do you work with?',
        placeholder: '',
        isRequired: true,
        order: 7,
      },
      'custom:Usecase': {
        label: 'What types of questions do you want answers to?',
        placeholder: '',
        isRequired: false,
        order: 8,
      },
      'custom:Images': {
        label: 'Number of images you work with',
        placeholder: 'E.g. 100k',
        isRequired: false,
        order: 9,
      },
      'custom:DataHost': {
        label: 'Where are the images hosted?',
        placeholder: 'E.g. S3, Google Drive...',
        isRequired: true,
        order: 10,
      },
      'custom:Workflow': {
        label: 'What is your existing workflow?',
        placeholder: 'E.g. Drone -> ArcGIS Imagery Server -> Python',
        isRequired: false,
        order: 11,
      },
      'custom:Painpoints': {
        label: 'Which parts of your workflow are the most painful?',
        placeholder: '',
        isRequired: false,
        order: 12,
      },
      'custom:Source': {
        label: 'How did you find us?',
        placeholder: '',
        isRequired: false,
        order: 13,
      },
    },
    confirmSignIn: {
      // eslint-disable-next-line camelcase
      confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
  };

  // eslint-disable-next-line react/display-name
  return (props) => {
    useEffect(() => {
      const fetch = async () => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');

        // Abort if "magic link" doesn't have access token
        if (token === null) {
          return;
        }

        const decoded = jwtDecode(token);

        try {
          // Try to get authenticated user through network request
          await Auth.currentAuthenticatedUser();
        } catch {
          // If we couldn't fetch current user,
          // try to sign in with the access token from the "magic link".
          try {
            await Auth.signIn({
              username: decoded.username,
              password: token,
              validationData: {
                token: token,
                source: 'magic',
              },
            });

            // Reload page by redirecting user to this same page
            window.location.href = window.location.origin;
          } catch (error) {
            console.error('Error signing in:', error);
          }
        }
      };

      fetch();
    }, []);

    const handleSignOut = useCallback(async () => {
      try {
        await Auth.signOut();
        localStorage.removeItem(USERNAME_LOCALSTORAGE_KEY);
        window.location.reload();
      } catch (error) {
        console.error('Error signing out:', error);
      }
    }, []);

    return (
      <Authenticator formFields={formFields} components={components}>
        <WrappedComponent {...props} signOut={handleSignOut} />
      </Authenticator>
    );
  };
};

export default withCustomAuthenticator;
