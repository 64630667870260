import React, { useCallback, useEffect, useState, useRef } from 'react';

import classNames from 'classnames';
import EXIF from 'exif-js';
import { getDistance } from 'geolib';
import L from 'leaflet';
import {
  DownloadCloud,
  Image,
  Loader2,
  Mail,
  Plus,
  Search,
  Triangle,
  UserCircle2,
} from 'lucide-react';
import PropTypes from 'prop-types';

import {
  fetchUserDatasets,
  searchComparisonsBasedOnInputText,
  searchImagesBasedOnInputImage,
  searchImagesBasedOnInputText,
} from '../api';
import {
  COMPARABLE_DATASETS,
  COMPATIBLE_DATASETS,
  REGION_TO_COORDINATES,
  DATASET_DESCRIPTIONS,
  DATASET_TO_IMAGERY_TYPE,
  DEFAULT_DATA_SOURCE,
  DEFAULT_QUERY_HISTORY,
  DEFAULT_SECONDARY_DATA_SOURCE,
  IMAGE_SEARCH_DISPLAY_TEXT,
  IMAGE_SEARCH_FILE_SIZE_LIMIT_IN_BYTES,
  IMAGE_SEARCH_QUERY_TEXT,
  IMAGERY_TYPES,
  MAX_RESULT_LAYER_STACK,
  IMAGE_GALLERY_PAGE_SIZE,
  MILE_IN_METERS,
  SKIP_DATASETS,
} from '../constants';
import {
  base64ToBlob,
  convertDMSToDD,
  filterComparisonObjectsByGeoboundary,
  filterImageObjectsByGeoboundary,
  getBase64FromDataURL,
  getDatasets,
  getLatLng,
  getRegionFromDataset,
} from '../utils';

import DatatypeIcon from './DatatypeIcon';
import HexagonIcon from './HexagonIcon';
import QueryHistoryItem from './QueryHistoryItem';
import TeraLensIcon from './TeraLensIcon';

// Promisify the EXIF.getData call
const getExifData = (blob) => {
  return new Promise((resolve, reject) => {
    try {
      EXIF.getData(blob, function () {
        resolve(this);
      });
    } catch (error) {
      reject(error);
    }
  });
};

// Generate unique fake IDs for each "score" in the report
let nextScoreId = 0;

// TODO: adjust for hamburger (mobile)
const SideBar = ({
  signOut,
  onNewImageObjects,
  onNewComparisonObjects,
  compareMode,
  setCompareMode,
  primaryDataSource,
  setPrimaryDataSource,
  setHasUserQueried,
  setIsQueryLoading,
  isQueryLoading,
  geoboundaryRef,
  mapRef,
  drawnItemsRef,
  individualResultsQueue,
  comparisonResultsQueue,
  setIndividualPageData,
  setComparisonPageData,
  setPageIndex,
  onResetResults,
  onPlotMapOverlays,
  setScoreQueue,
  geojsonLink,
  geocodedLocation,
  setReportCardMode,
}) => {
  const fileInput = useRef(null);
  const searchBoxRef = useRef(null);

  const [queryText, setQueryText] = useState('');
  const [isExporting, setIsExporting] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [isFetchingDatasets, setIsFetchingDatasets] = useState(false);
  const [secondaryDataSource, setSecondaryDataSource] = useState(DEFAULT_SECONDARY_DATA_SOURCE);
  const [datasets, setDatasets] = useState([DEFAULT_DATA_SOURCE]);
  const [queryHistory, setQueryHistory] = useState(DEFAULT_QUERY_HISTORY);
  const [userSubmittedInvalidFile, setUserSubmittedInvalidFile] = useState(false);
  const [checkboxState, setCheckboxState] = useState({
    [IMAGERY_TYPES.VERTICAL_AERIAL]: true,
    [IMAGERY_TYPES.OBLIQUE_AERIAL]: true,
    [IMAGERY_TYPES.STREET_VIEW]: true,
  });

  const isSearchButtonDisabled = isQueryLoading || !queryText;
  const isImageSearchButtonDisabled = isQueryLoading || compareMode;
  const isSelectDisabled = isQueryLoading || isFetchingDatasets;

  const handleOnPrimaryDataSourceChange = (event) => {
    const newPrimaryDataset = event.target.value;

    // if selected dataset is not comparable, turn off compare mode and reset
    // if selected dataset is same as compare, turn off compare mode and reset
    // NOTE: No need to clear results because it's done upstream (due to mode change)
    if (
      compareMode &&
      (newPrimaryDataset === secondaryDataSource ||
        !COMPARABLE_DATASETS.includes(newPrimaryDataset) ||
        !COMPATIBLE_DATASETS[secondaryDataSource].includes(newPrimaryDataset))
    ) {
      setCompareMode(false);
      setSecondaryDataSource(DEFAULT_SECONDARY_DATA_SOURCE);
      onResetResults();
    }

    setPrimaryDataSource(newPrimaryDataset);
    onPlotMapOverlays(newPrimaryDataset);

    const filteredDatasets = getDatasets(newPrimaryDataset);
    const availableImageryTypes = filteredDatasets.map(
      (dataset) => DATASET_TO_IMAGERY_TYPE[dataset]
    );

    setCheckboxState({
      [IMAGERY_TYPES.VERTICAL_AERIAL]: availableImageryTypes.includes(
        IMAGERY_TYPES.VERTICAL_AERIAL
      ),
      [IMAGERY_TYPES.OBLIQUE_AERIAL]: availableImageryTypes.includes(IMAGERY_TYPES.OBLIQUE_AERIAL),
      [IMAGERY_TYPES.STREET_VIEW]: availableImageryTypes.includes(IMAGERY_TYPES.STREET_VIEW),
    });

    if (!mapRef.current) {
      console.warn('Attempted to move to a marker without first initializing the map.');
      return;
    }

    const region = getRegionFromDataset(newPrimaryDataset);

    if (region === null) {
      console.warn('Attempted to zoom in to a dataset without coordinate mapping.');
      return;
    }

    // Only change the map's view if there's no geobound present
    if (geoboundaryRef.current === null) {
      mapRef.current.setView(REGION_TO_COORDINATES[region], 8);
    }
  };

  const handleOnSecondaryDataSourceChange = async (event) => {
    const newSecondaryDataset = event.target.value;
    setSecondaryDataSource(newSecondaryDataset);

    // Disable compare mode if "none" is selected
    // NOTE: No need to clear results because it's done upstream (due to mode change)
    if (newSecondaryDataset === DEFAULT_SECONDARY_DATA_SOURCE) {
      setCompareMode(false);

      // If we're switching from "comparison" to "individual",
      // reset the results in the map.
      if (compareMode) {
        onResetResults();
      }
      return;
    }

    setCompareMode(true);

    // If we're switching from "individual" to "comparison",
    // reset the results in the map.
    if (!compareMode) {
      onResetResults();
    }
  };

  const handleCheckboxChange = (event) => {
    setCheckboxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    });
  };

  const isCheckboxDisabled = (imageryType, dataSource) => {
    const datasets = getDatasets(dataSource);
    const filteredDatasets = datasets.filter(
      (dataset) => DATASET_TO_IMAGERY_TYPE[dataset] === imageryType
    );
    return filteredDatasets.length === 0;
  };

  // Use useEffect to log the state after it updates
  useEffect(() => {
    console.log(checkboxState);
  }, [checkboxState]); // Dependency array ensures this runs when checkboxState changes

  const handleOnInputChange = (event) => {
    setQueryText(event.target.value);

    // Clear any "invalid image" errors
    setUserSubmittedInvalidFile(false);
  };

  const handleOnEnterPressed = (event) => {
    if (event.key === 'Enter') {
      handleOnSubmit();
    }
  };

  const handleOnShowInputButtonClick = () => {
    setIsInputVisible(true);
  };

  const handleOnContactButtonClick = () => {
    window.location.href = 'mailto:info@tera.earth';
  };

  const handleOnQueryHistoryItemClick = useCallback((queryText) => {
    setIsInputVisible(true);
    setQueryText(queryText);

    // Clear any "invalid image" errors
    setUserSubmittedInvalidFile(false);

    // Focus on search box after appending text
    if (searchBoxRef.current) {
      searchBoxRef.current.focus();
    } else {
      console.error('Attempted focusing on an input that does not exist!');
    }
  }, []);

  const handleOpenFilePrompt = () => {
    if (fileInput.current) {
      fileInput.current.click();
    } else {
      console.error('Attempted to open file input that does not exist!');
    }
  };

  const getExifDataFromImage = async (imageBlob) => {
    const exifData = await getExifData(imageBlob);

    const lat = EXIF.getTag(exifData, 'GPSLatitude');
    const lng = EXIF.getTag(exifData, 'GPSLongitude');
    const latRef = EXIF.getTag(exifData, 'GPSLatitudeRef'); // 'N' or 'S'
    const lngRef = EXIF.getTag(exifData, 'GPSLongitudeRef'); // 'E' or 'W'

    let latitude = null;
    let longitude = null;

    if (lat && lng && latRef && lngRef) {
      latitude = convertDMSToDD(lat[0], lat[1], lat[2], latRef);
      longitude = convertDMSToDD(lng[0], lng[1], lng[2], lngRef);
    }

    return { latitude, longitude };
  };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleOnFileChange = async (event) => {
    const files = event.target.files;

    if (!files || files.length === 0) {
      return;
    }

    // Multiple images for claim fraud PoC
    if (files.length > 1) {
      setScoreQueue([]);
      setReportCardMode('claimFraud');
      drawnItemsRef.current.clearLayers();
      geoboundaryRef.current = null;

      // Clear any "invalid image" errors
      setUserSubmittedInvalidFile(false);

      const scores = [];

      for (const file of files) {
        if (file.size > IMAGE_SEARCH_FILE_SIZE_LIMIT_IN_BYTES) {
          setUserSubmittedInvalidFile(true);
          fileInput.current.value = '';
          return;
        }

        let dataURL, mimeType, base64Content;

        try {
          dataURL = await readFileAsDataURL(file);
          ({ mimeType, base64Content } = getBase64FromDataURL(dataURL));
        } catch (error) {
          console.error('Error reading the file:', error);
          return;
        }

        setIsQueryLoading(true);

        // Get latitude and longitude from EXIF data
        const imageBlob = base64ToBlob(base64Content, mimeType);
        let exiflatitude, exiflongitude;
        try {
          ({ latitude: exiflatitude, longitude: exiflongitude } =
            await getExifDataFromImage(imageBlob));
        } catch (error) {
          console.error('Error getting EXIF data:', error);
        }

        // get address from file name (remove extension)
        const address = file.name.split('.')[0];

        // get latitude and longitude from address
        let addressLatitude, addressLongitude;
        try {
          ({ latitude: addressLatitude, longitude: addressLongitude } = await getLatLng(address));
        } catch (error) {
          console.error('Error getting address data:', error);
        }

        let distance;
        if (addressLatitude && addressLongitude && exiflatitude && exiflongitude) {
          distance = getDistance(
            { latitude: exiflatitude, longitude: exiflongitude },
            { latitude: addressLatitude, longitude: addressLongitude }
          );
        }

        if (addressLatitude && addressLongitude) {
          const circle = L.circle([addressLatitude, addressLongitude], {
            radius: 0.04 * MILE_IN_METERS,
          });
          const geojson = circle.toGeoJSON();

          // NOTE: this makes the geojson non-standard
          if (circle instanceof L.Circle) {
            geojson.properties.radius = circle.getRadius();
          }

          // update the geoboundary
          geoboundaryRef.current = geojson;
        } else {
          // clear the circle and the geoboundary
          drawnItemsRef.current.clearLayers();
          geoboundaryRef.current = null;
        }

        try {
          const { results: imageObjects, score } = await searchImagesBasedOnInputImage({
            dataSource: primaryDataSource,
            encodedImage: base64Content,
            geoboundary: geoboundaryRef.current,
            scoreDepth: 1,
          });

          // TODO: display image in gallery
          console.log('imageObjects:', imageObjects);
          scores.push({ id: nextScoreId++, address, score, distance });

          setHasUserQueried(true);
        } catch (error) {
          console.error('Error searching for the image:', error);
        }
        setIsQueryLoading(false);
      }

      // convert raw scores to percentile scores
      const percentileScores = [];
      for (const score of scores) {
        const percentileScore = scores.filter((s) => s.score < score.score).length / scores.length;
        percentileScores.push({ ...score, percentileScore });

        // add to report card
        setScoreQueue((previousScores) => [
          ...previousScores,
          {
            id: score.id,
            queryText: score.address,
            score: percentileScore,
            distance: score.distance,
          },
        ]);
      }
    } else {
      // Single file for georeferencing search
      const file = files[0];

      if (file.size > IMAGE_SEARCH_FILE_SIZE_LIMIT_IN_BYTES) {
        setUserSubmittedInvalidFile(true);
        fileInput.current.value = '';
        return;
      }

      // Clear any "invalid image" errors
      setUserSubmittedInvalidFile(false);

      let dataURL, mimeType, base64Content;

      try {
        dataURL = await readFileAsDataURL(file);
        ({ mimeType, base64Content } = getBase64FromDataURL(dataURL));
      } catch (error) {
        console.error('Error reading the file:', error);
        return;
      }

      setIsQueryLoading(true);

      // Get latitude and longitude from EXIF data
      const imageBlob = base64ToBlob(base64Content, mimeType);

      let latitude, longitude;
      try {
        ({ latitude, longitude } = await getExifDataFromImage(imageBlob));
      } catch (error) {
        console.error('Error getting EXIF data:', error);
      }

      if (latitude && longitude) {
        const circle = L.circle([latitude, longitude], { radius: 0.6 * MILE_IN_METERS });
        const geojson = circle.toGeoJSON();

        // NOTE: this makes the geojson non-standard
        if (circle instanceof L.Circle) {
          geojson.properties.radius = circle.getRadius();
        }

        drawnItemsRef.current.clearLayers();
        drawnItemsRef.current.addLayer(circle);

        // update the geoboundary and map view
        geoboundaryRef.current = geojson;
        mapRef.current.setView([latitude, longitude], 14);
      } else {
        // clear the circle and the geoboundary
        drawnItemsRef.current.clearLayers();
        geoboundaryRef.current = null;
      }

      // Log the new image upload as a QueryHistoryItem
      setQueryHistory((previousHistory) => [
        // Eliminate any redundancies before logging the new query
        ...previousHistory.filter((query) => query !== IMAGE_SEARCH_QUERY_TEXT),
        IMAGE_SEARCH_QUERY_TEXT,
      ]);

      try {
        const { results: imageObjects } = await searchImagesBasedOnInputImage({
          dataSource: primaryDataSource,
          encodedImage: base64Content,
          geoboundary: geoboundaryRef.current,
        });

        const geoboundedResults =
          geoboundaryRef.current === null
            ? imageObjects
            : filterImageObjectsByGeoboundary(imageObjects, geoboundaryRef.current);

        onNewImageObjects(geoboundedResults);

        // Remove oldest layer
        if (individualResultsQueue.current.length === MAX_RESULT_LAYER_STACK) {
          individualResultsQueue.current.pop();
        }

        // Append new results into the existing results queue
        individualResultsQueue.current.unshift(geoboundedResults);

        // Display the first N images
        setIndividualPageData(geoboundedResults.slice(0, IMAGE_GALLERY_PAGE_SIZE));
        setPageIndex(0);

        setHasUserQueried(true);
      } catch (error) {
        console.error('Error processing the image:', error);
      }
      setIsQueryLoading(false);
    }

    // Clear file input
    fileInput.current.value = '';
  };

  const handleOnSubmit = async () => {
    const trimmedQuery = queryText.trim();

    if (trimmedQuery.length === 0) {
      return;
    }

    setIsQueryLoading(true);

    setQueryHistory((previousHistory) => [
      // Eliminate any redundancies before logging the new query
      ...previousHistory.filter((query) => query !== queryText),
      queryText,
    ]);

    try {
      // Search in "compare mode"
      if (compareMode) {
        const { results: comparisonObjects } = await searchComparisonsBasedOnInputText({
          queryText,
          primaryDataSource,
          secondaryDataSource,
          geoboundary: geoboundaryRef.current,
        });

        const geoboundedResults =
          geoboundaryRef.current === null
            ? comparisonObjects
            : filterComparisonObjectsByGeoboundary(comparisonObjects, geoboundaryRef.current);

        onNewComparisonObjects(geoboundedResults);

        // Remove oldest layer
        if (comparisonResultsQueue.current.length === MAX_RESULT_LAYER_STACK) {
          comparisonResultsQueue.current.pop();
        }

        // Append new results into the existing results queue
        comparisonResultsQueue.current.unshift(geoboundedResults);

        // Display the first N images
        setComparisonPageData(geoboundedResults.slice(0, IMAGE_GALLERY_PAGE_SIZE));
        setPageIndex(0);

        setIsQueryLoading(false);
        setHasUserQueried(true);
        return;
      }

      // Search in "normal mode"
      const { results: imageObjects, score } = await searchImagesBasedOnInputText({
        queryText,
        dataSource: getDatasets(primaryDataSource, checkboxState),
        geoboundary: geoboundaryRef.current,
        getPercentile: geocodedLocation !== null,
      });

      const geoboundedResults =
        geoboundaryRef.current === null
          ? imageObjects
          : filterImageObjectsByGeoboundary(imageObjects, geoboundaryRef.current);

      onNewImageObjects(geoboundedResults);

      // Remove oldest layer
      if (individualResultsQueue.current.length === MAX_RESULT_LAYER_STACK) {
        individualResultsQueue.current.pop();
      }

      // Append new results into the existing results queue
      individualResultsQueue.current.unshift(geoboundedResults);

      // Display the first N images
      setIndividualPageData(geoboundedResults.slice(0, IMAGE_GALLERY_PAGE_SIZE));
      setPageIndex(0);

      // Store the adjacency score to display it later
      if (geocodedLocation !== null) {
        setReportCardMode('adjacentRisk');
        setScoreQueue((previousScores) => [
          ...previousScores,
          { id: nextScoreId++, queryText, score },
        ]);
      }

      setIsQueryLoading(false);
      setHasUserQueried(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsQueryLoading(false);
    }
  };

  const handleExportGeoJSON = async () => {
    setIsExporting(true);
    try {
      const data = await fetch(geojsonLink);
      const blob = await data.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      document.body.appendChild(link);

      link.href = url;
      link.download = 'results.geojson';
      link.style = 'display: none';

      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed exporting GeoJSON:', error);
    }
    setIsExporting(false);
  };

  // Get the datasets that the user is authorized to view
  useEffect(() => {
    const fetch = async () => {
      setIsFetchingDatasets(true);
      try {
        const datasets = await fetchUserDatasets();
        setDatasets(datasets);
      } catch (error) {
        console.error('Error fetching datasets:', error);
      }
      setIsFetchingDatasets(false);
    };

    fetch();
  }, []);

  return (
    <div className="flex h-full w-full flex-col overflow-y-auto bg-slate-50 pt-8 dark:bg-slate-900">
      <div className="relative flex flex-row px-4">
        <TeraLensIcon />
        <h2 className="px-3 text-xl font-medium text-slate-800 dark:text-slate-200">
          <span className="font-bold">EarthQuery</span>
        </h2>
        {geojsonLink.length > 0 && (
          <button
            className="absolute right-0 mr-4 rounded-md bg-slate-200 p-1 text-slate-600 transition-colors hover:bg-slate-300 dark:bg-slate-800 dark:text-slate-200 dark:hover:bg-slate-950"
            onClick={handleExportGeoJSON}
            title="Download GeoJSON results"
            disabled={isExporting}
          >
            {isExporting ? <Loader2 className="animate-spin" /> : <DownloadCloud />}
          </button>
        )}
      </div>
      <div className="mt-8 w-full px-2">
        {isInputVisible ? (
          <div className="flex w-full flex-col">
            <div
              className={classNames(
                'flex w-full flex-row rounded-lg border border-slate-300',
                userSubmittedInvalidFile && '!border-red-500'
              )}
            >
              <input
                type="text"
                value={queryText}
                onChange={handleOnInputChange}
                onKeyDown={handleOnEnterPressed}
                className="w-full rounded-l-lg py-4 pl-2 text-sm font-medium text-slate-700 transition-colors disabled:cursor-not-allowed disabled:bg-gray-300"
                placeholder="Write a query..."
                disabled={isQueryLoading}
                ref={searchBoxRef}
                autoFocus
              />
              <input
                type="file"
                className="hidden"
                ref={fileInput}
                onChange={handleOnFileChange}
                accept="image/jpeg, image/png"
                multiple
              />
              {!compareMode && (
                <button
                  onClick={handleOpenFilePrompt}
                  className="cursor-pointer bg-white px-2 py-2 transition-colors hover:bg-gray-200 disabled:cursor-not-allowed disabled:bg-gray-300"
                  disabled={isImageSearchButtonDisabled}
                  title="Upload an image"
                >
                  <Image size={20} className="text-slate-700" />
                </button>
              )}
              <button
                onClick={handleOnSubmit}
                disabled={isSearchButtonDisabled}
                className="cursor-pointer rounded-r-lg bg-white px-2 py-2 text-sm transition-colors hover:bg-slate-200 disabled:cursor-not-allowed disabled:bg-gray-300"
                title="Submit text"
              >
                {isQueryLoading ? (
                  <Loader2 size={20} className="animate-spin text-slate-700" />
                ) : (
                  <Search size={20} className="text-slate-700" />
                )}
              </button>
            </div>
            {userSubmittedInvalidFile && (
              <p className="ml-1 mt-1 text-sm text-red-500">File size exceeded.</p>
            )}
          </div>
        ) : (
          <button
            className="flex w-full flex-row items-center gap-x-4 rounded-lg border border-slate-300 p-4 text-slate-700 transition-colors hover:bg-slate-200 dark:border-slate-700 dark:text-slate-200 dark:hover:bg-slate-800"
            onClick={handleOnShowInputButtonClick}
          >
            <Plus size={20} />
            <span className="text-sm font-medium">Free-Form Query</span>
          </button>
        )}
      </div>
      {/* Query History */}
      <div className="h-1/3 space-y-4 overflow-y-auto border-b border-slate-300 px-2 py-4 dark:border-slate-700">
        {queryHistory
          .slice()
          .reverse()
          .map((query) => (
            <QueryHistoryItem
              key={query === IMAGE_SEARCH_QUERY_TEXT ? IMAGE_SEARCH_QUERY_TEXT : query}
              query={query === IMAGE_SEARCH_QUERY_TEXT ? IMAGE_SEARCH_DISPLAY_TEXT : query}
              onClick={query === IMAGE_SEARCH_QUERY_TEXT ? null : handleOnQueryHistoryItemClick}
              className={query === IMAGE_SEARCH_QUERY_TEXT ? 'italic' : ''}
              disabled={isQueryLoading}
            />
          ))}
      </div>
      {/* Settings */}
      <div className="mt-auto w-full space-y-4 px-2 py-4">
        {/* Checkboxes Section */}
        <div className="w-full items-center justify-between gap-x-2 rounded-lg px-3 py-2 text-sm font-medium text-slate-700 dark:text-slate-200">
          <div className="mb-2 flex flex-row items-center gap-x-2">
            <DatatypeIcon />
            <span className="flex-grow">Data Types</span>
          </div>
          <div className="mb-2 flex items-center ">
            <input
              type="checkbox"
              id="checkbox1"
              name={IMAGERY_TYPES.VERTICAL_AERIAL}
              checked={checkboxState[IMAGERY_TYPES.VERTICAL_AERIAL]}
              onChange={handleCheckboxChange}
              disabled={isCheckboxDisabled(IMAGERY_TYPES.VERTICAL_AERIAL, primaryDataSource)}
              className="h-4 w-4"
            />
            <label htmlFor="checkbox1" className="ml-2 text-sm font-medium text-gray-700">
              Vertical Aerial
            </label>
          </div>
          <div className="mb-2 flex items-center">
            <input
              type="checkbox"
              id="checkbox2"
              name={IMAGERY_TYPES.OBLIQUE_AERIAL}
              checked={checkboxState[IMAGERY_TYPES.OBLIQUE_AERIAL]}
              onChange={handleCheckboxChange}
              disabled={isCheckboxDisabled(IMAGERY_TYPES.OBLIQUE_AERIAL, primaryDataSource)}
              className="h-4 w-4"
            />
            <label htmlFor="checkbox2" className="ml-2 text-sm font-medium text-gray-700">
              Oblique Aerial
            </label>
          </div>
          <div className="mb-2 flex items-center">
            <input
              type="checkbox"
              id="checkbox3"
              name={IMAGERY_TYPES.STREET_VIEW}
              checked={checkboxState[IMAGERY_TYPES.STREET_VIEW]}
              onChange={handleCheckboxChange}
              disabled={isCheckboxDisabled(IMAGERY_TYPES.STREET_VIEW, primaryDataSource)}
              className="h-4 w-4"
            />
            <label htmlFor="checkbox3" className="ml-2 text-sm font-medium text-gray-700">
              Street View
            </label>
          </div>
        </div>
        {/* Existing Primary Data Section */}
        <div className="w-full items-center justify-between gap-x-2 rounded-lg px-3 py-2 text-sm font-medium text-slate-700 dark:text-slate-200">
          <div className="mb-2 flex flex-row items-center gap-x-2">
            <HexagonIcon />
            <span className="flex-grow">Dataset</span>
          </div>
          <select
            value={primaryDataSource}
            onChange={handleOnPrimaryDataSourceChange}
            disabled={isSelectDisabled}
            className="w-full rounded-lg border border-slate-300 p-2 text-left text-sm font-medium text-slate-700 transition-colors disabled:cursor-not-allowed disabled:bg-gray-300"
          >
            <option disabled>-- SELECT DATASET SOURCE --</option>
            {datasets
              .filter((key) => !SKIP_DATASETS.includes(key))
              .map((key) => (
                <option key={key} value={key}>
                  {DATASET_DESCRIPTIONS[key]}
                </option>
              ))}
            <option value="user" disabled>
              Your own data (Email Us!)
            </option>
          </select>
        </div>
        <div className="w-full items-center justify-between gap-x-2 rounded-lg px-3 py-2 text-sm font-medium text-slate-700 dark:text-slate-200">
          <div className="mb-2 flex flex-row items-center gap-x-2">
            <Triangle size={24} />
            <span className="flex-grow text-left">Change Detection</span>
          </div>
          <select
            value={secondaryDataSource}
            onChange={handleOnSecondaryDataSourceChange}
            disabled={isSelectDisabled}
            className="w-full rounded-lg border border-slate-300 p-2 text-left text-sm font-medium text-slate-700 transition-colors disabled:cursor-not-allowed disabled:bg-gray-300"
          >
            <option disabled>-- SELECT 2ND PAIRED DATASET TO QUERY CHANGES --</option>
            <option value={DEFAULT_SECONDARY_DATA_SOURCE}>None</option>
            {COMPATIBLE_DATASETS[primaryDataSource].map((key) => (
              <option key={key} value={key}>
                {DATASET_DESCRIPTIONS[key]}
              </option>
            ))}
          </select>
        </div>
        <button
          className="flex w-full flex-row items-center gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium text-slate-700 transition-colors hover:bg-slate-200 dark:text-slate-200 dark:hover:bg-slate-800"
          onClick={signOut}
        >
          <UserCircle2 size={24} />
          <span>Sign Out</span>
        </button>
        <button
          className="flex w-full flex-row items-center gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium text-slate-700 transition-colors hover:bg-slate-200 dark:text-slate-200 dark:hover:bg-slate-800"
          onClick={handleOnContactButtonClick}
        >
          <Mail size={24} />
          <span>info@tera.earth</span>
        </button>
      </div>
    </div>
  );
};

SideBar.propTypes = {
  signOut: PropTypes.func.isRequired,
  onNewImageObjects: PropTypes.func.isRequired,
  onNewComparisonObjects: PropTypes.func.isRequired,
  compareMode: PropTypes.bool.isRequired,
  setCompareMode: PropTypes.func.isRequired,
  primaryDataSource: PropTypes.string.isRequired,
  setPrimaryDataSource: PropTypes.func.isRequired,
  setHasUserQueried: PropTypes.func.isRequired,
  setIsQueryLoading: PropTypes.func.isRequired,
  isQueryLoading: PropTypes.bool.isRequired,
  geoboundaryRef: PropTypes.any.isRequired,
  mapRef: PropTypes.any.isRequired,
  drawnItemsRef: PropTypes.any.isRequired,
  individualResultsQueue: PropTypes.any.isRequired,
  comparisonResultsQueue: PropTypes.any.isRequired,
  setIndividualPageData: PropTypes.func.isRequired,
  setComparisonPageData: PropTypes.func.isRequired,
  setPageIndex: PropTypes.func.isRequired,
  onResetResults: PropTypes.func.isRequired,
  onPlotMapOverlays: PropTypes.func.isRequired,
  setScoreQueue: PropTypes.func.isRequired,
  geojsonLink: PropTypes.string.isRequired,
  geocodedLocation: PropTypes.object,
  setReportCardMode: PropTypes.func.isRequired,
};

export default SideBar;
