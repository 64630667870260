/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://ylgo3uhvcvb5tcmraxisccivlq.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-lnoj4hzxfbeqpmodyplrzbku5q",
    "aws_cloud_logic_custom": [
        {
            "name": "images",
            "endpoint": "https://se1548g8e1.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        },
        {
            "name": "search",
            "endpoint": "https://eazvgf4mi6.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:5de1356d-7489-4fb7-a248-7b09facfcb1d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_DWMgtGWNL",
    "aws_user_pools_web_client_id": "1k61qveac4ot6cminb5kqv3uop",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ImageNotes-production",
            "region": "us-east-2"
        },
        {
            "tableName": "MagicLinkAuthTokens-production",
            "region": "us-east-2"
        },
        {
            "tableName": "QueryLogs-production",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "amplifyapp8e0d0a6069254be195816df47fd4b56a143145-production",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
