import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const ReportCard = ({ className, scores, mode }) => {
  const getGradeAndColor = (score) => {
    let grade, color;
    if (score <= 0.5) {
      grade = 'A';
      color = 'text-green-600'; // Green color class
    } else if (score <= 0.65) {
      grade = 'B';
      color = 'text-yellow-600'; // Yellow color class
    } else if (score <= 0.82) {
      grade = 'C';
      color = 'text-orange-600'; // Orange color class
    } else {
      grade = 'F';
      color = 'text-red-600'; // Red color class
    }
    return { grade, color };
  };

  const getGradeAndColorClaimFraud = (score) => {
    let grade, color;
    if (score >= 0.65) {
      grade = 'A';
      color = 'text-green-600'; // Green color class
    } else if (score >= 0.5) {
      grade = 'B';
      color = 'text-yellow-600'; // Yellow color class
    } else if (score >= 0.3) {
      grade = 'C';
      color = 'text-orange-600'; // Orange color class
    } else {
      grade = 'D';
      color = 'text-red-600'; // Red color class
    }
    return { grade, color };
  };

  const renderScoreItem = (result) => {
    if (mode === 'adjacentRisk') {
      const { grade, color } = getGradeAndColor(result.score);
      return (
        <li key={result.queryText}>
          <span className="text-lg">{result.queryText} - </span>
          <span className={`${color} font-bold`}>{result.score >= 0 ? grade : 'N/A'}</span>
        </li>
      );
    } else if (mode === 'claimFraud') {
      const { grade, color } = getGradeAndColorClaimFraud(result.score);
      return (
        <li key={result.queryText}>
          <span className="text-lg">{result.queryText} - </span>
          <span className={`${color} font-bold`}>{result.score >= 0 ? grade : 'N/A'}</span>
          <span className="text-lg" style={{ fontStyle: 'italic' }}>
            - {result.distance !== undefined ? `${result.distance}m` : 'N/A'}
          </span>
        </li>
      );
    }
  };

  return (
    <div
      className={classNames(
        'rounded-md border border-slate-300 px-2 py-2 dark:border-slate-700',
        className
      )}
    >
      <h1 className="font-bold">Report Card</h1>
      {mode === 'adjacentRisk' && (
        <p className="mb-1 border-l-2 pl-1 text-xs">
          Compared against other properties in the region
        </p>
      )}
      <ul className="ml-6 list-none">
        {(mode === 'claimFraud' ? [...scores].sort((a, b) => b.score - a.score) : scores).map(
          renderScoreItem
        )}
      </ul>
    </div>
  );
};

ReportCard.defaultProps = {
  className: '',
  mode: 'adjacentRisk', // default mode
};

ReportCard.propTypes = {
  className: PropTypes.string,
  scores: PropTypes.arrayOf(PropTypes.object).isRequired,
  mode: PropTypes.oneOf(['adjacentRisk', 'claimFraud']),
};

export default ReportCard;
