import React, { memo } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const QueryHistoryItem = ({ query, onClick = null, className = '', disabled = false }) => {
  const handleOnClick = () => {
    onClick?.(query);
  };

  return (
    <button
      id="query-history-item"
      title={query}
      disabled={disabled}
      className="w-full truncate rounded-lg px-3 py-2 text-left transition-colors duration-200 hover:bg-slate-200 focus:outline-none disabled:cursor-not-allowed dark:hover:bg-slate-800"
      onClick={handleOnClick}
    >
      <span
        className={classNames(
          'text-ellipsis text-sm font-medium text-slate-700 dark:text-slate-200',
          className
        )}
      >
        {query}
      </span>
    </button>
  );
};

QueryHistoryItem.propTypes = {
  query: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

QueryHistoryItem.defaultProps = {
  onClick: null,
  className: '',
  disabled: false,
};

export default memo(QueryHistoryItem);
