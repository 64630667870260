import React from 'react';

import { Frown } from 'lucide-react';

const EmptyResultsBanner = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center text-center">
      <Frown size={128} className="mb-8" />
      <p>Sorry, we don&apos;t have results for that query.</p>
      <p>
        Contact us at{' '}
        <a className="hover:underline" href="mailto:info@tera.earth">
          info@tera.earth
        </a>
        .
      </p>
    </div>
  );
};

export default EmptyResultsBanner;
