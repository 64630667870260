import { API } from 'aws-amplify';

import { API_RESULTS_LIMIT, USERNAME_LOCALSTORAGE_KEY } from './constants';

const nullifyInvalidCoordinate = (coordinate) => {
  return coordinate === null || coordinate === undefined || coordinate === -999 ? null : coordinate;
};

export const searchImagesBasedOnInputText = async ({
  queryText,
  dataSource,
  geoboundary,
  getPercentile,
}) => {
  const username = localStorage.getItem(USERNAME_LOCALSTORAGE_KEY);

  const params = new URLSearchParams({
    query: queryText,
    dataset: dataSource,
    username: username,
  });

  if (geoboundary !== null) {
    params.set('geojson', JSON.stringify(geoboundary));
  }

  if (getPercentile !== null) {
    params.set('getPercentile', getPercentile);
  }

  const response = await API.get('search', `/search?${params.toString()}`);

  const imageObjects = response?.imageObjects ?? [];
  const score = response?.scorePercentile ?? -1;

  const parsedImageObjects = imageObjects.map((imageObject) => ({
    id: encodeURIComponent(imageObject.image_url),
    url: imageObject.image_url,
    coordinates: [
      nullifyInvalidCoordinate(imageObject.lat),
      nullifyInvalidCoordinate(imageObject.lng),
    ],
    xmin: imageObject.bbox?.xmin || null,
    ymin: imageObject.bbox?.ymin || null,
    xmax: imageObject.bbox?.xmax || null,
    ymax: imageObject.bbox?.ymax || null,
    note: '', // TODO: fetch notes eventually
  }));

  return {
    count: parsedImageObjects.length,
    results: parsedImageObjects.slice(0, API_RESULTS_LIMIT),
    score: score,
  };
};

export const searchComparisonsBasedOnInputText = async ({
  queryText,
  primaryDataSource,
  secondaryDataSource,
  geoboundary,
}) => {
  const username = localStorage.getItem(USERNAME_LOCALSTORAGE_KEY);

  const params = new URLSearchParams({
    query: queryText,
    dataset: `${primaryDataSource},${secondaryDataSource}`,
    username: username,
    changeDetection: true,
  });

  if (geoboundary !== null) {
    params.set('geojson', JSON.stringify(geoboundary));
  }

  const response = await API.get('search', `/search?${params.toString()}`);

  const beforeImageObjects = response?.beforeImageObjects ?? [];
  const afterImageObjects = response?.afterImageObjects ?? [];

  // NOTE: Leave note empty for now,
  // since compare mode doesn't need it,
  // and it avoids an extra network trip.
  const parsedImageObjects = beforeImageObjects.map((item1, index) => [
    {
      id: encodeURIComponent(item1.image_url),
      url: item1.image_url,
      coordinates: [nullifyInvalidCoordinate(item1.lat), nullifyInvalidCoordinate(item1.lng)],
      xmin: item1.bbox?.xmin || null,
      ymin: item1.bbox?.ymin || null,
      xmax: item1.bbox?.xmax || null,
      ymax: item1.bbox?.ymax || null,
      note: '',
    },
    {
      id: encodeURIComponent(afterImageObjects[index].image_url),
      url: afterImageObjects[index].image_url,
      // NOTE: Only using the "before" coordinates, since "before and after" should be the same
      coordinates: [nullifyInvalidCoordinate(item1.lat), nullifyInvalidCoordinate(item1.lng)],
      // TODO: Should we use the bounding box from item1 instead?
      xmin: afterImageObjects[index].bbox?.xmin || null,
      ymin: afterImageObjects[index].bbox?.ymin || null,
      xmax: afterImageObjects[index].bbox?.xmax || null,
      ymax: afterImageObjects[index].bbox?.ymax || null,
      note: '',
    },
  ]);

  return {
    count: parsedImageObjects.length,
    results: parsedImageObjects.slice(0, API_RESULTS_LIMIT),
  };
};

export const searchImagesBasedOnInputImage = async ({
  dataSource,
  encodedImage,
  geoboundary,
  scoreDepth = null,
}) => {
  const username = localStorage.getItem(USERNAME_LOCALSTORAGE_KEY);

  const params = new URLSearchParams({
    query: '',
    dataset: dataSource,
    username: username,
  });

  if (geoboundary !== null) {
    params.set('geojson', JSON.stringify(geoboundary));
  }

  if (scoreDepth !== null) {
    params.set('scoreDepth', scoreDepth);
  }

  const response = await API.post('search', `/search?${params.toString()}`, {
    body: {
      image: encodedImage,
    },
  });

  const imageObjects = response?.imageObjects ?? [];
  const score = response?.score ?? -1;

  const parsedImageObjects = imageObjects.map((imageObject) => ({
    id: encodeURIComponent(imageObject.image_url),
    url: imageObject.image_url,
    coordinates: [
      nullifyInvalidCoordinate(imageObject.lat),
      nullifyInvalidCoordinate(imageObject.lng),
    ],
    xmin: imageObject.bbox?.xmin || null,
    ymin: imageObject.bbox?.ymin || null,
    xmax: imageObject.bbox?.xmax || null,
    ymax: imageObject.bbox?.ymax || null,
    note: '', // TODO: fetch notes eventually
  }));

  return {
    count: parsedImageObjects.length,
    results: parsedImageObjects.slice(0, API_RESULTS_LIMIT),
    score: score,
  };
};

export const searchCategoriesBasedOnImages = async ({ dataSource, imageUrls }) => {
  const username = localStorage.getItem(USERNAME_LOCALSTORAGE_KEY);

  const params = new URLSearchParams({
    query: '',
    dataset: dataSource,
    username: username,
  });

  const response = await API.post('search', `/search?${params.toString()}`, {
    body: {
      // NOTE: imageUrls is an array of arrays of image urls
      categories: imageUrls,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const geojsonsUrl = response?.geojsonsUrl ?? [];
  const categorizedImageObjects = response?.categorizedImageObjects ?? [];

  const parsedImageObjects = categorizedImageObjects.map((category) =>
    category.map((imageObject) => ({
      id: encodeURIComponent(imageObject.image_url),
      url: imageObject.image_url,
      coordinates: [
        nullifyInvalidCoordinate(imageObject.lat),
        nullifyInvalidCoordinate(imageObject.lng),
      ],
      xmin: imageObject.bbox?.xmin || null,
      ymin: imageObject.bbox?.ymin || null,
      xmax: imageObject.bbox?.xmax || null,
      ymax: imageObject.bbox?.ymax || null,
      note: '', // TODO: fetch notes eventually
    }))
  );

  return {
    geojsonsUrl: geojsonsUrl,
    results: parsedImageObjects,
  };
};

export const fetchUserDatasets = async () => {
  const username = localStorage.getItem(USERNAME_LOCALSTORAGE_KEY);
  return await API.get('search', `/users/${username}/datasets`);
};

export const updateNote = async ({ dataSource, id, newText }) => {
  const init = {
    body: {
      note: newText,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const username = localStorage.getItem(USERNAME_LOCALSTORAGE_KEY);

  await API.post('images', `/user/${username}/dataset/${dataSource}/image/${id}/note`, init);
};
