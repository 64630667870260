import React, { useEffect, useRef, useCallback } from 'react';

import classNames from 'classnames';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import PropTypes from 'prop-types';

import { IMAGE_GALLERY_PAGE_SIZE } from '../constants';

import ComparisonImageItem from './ComparisonImageItem';
import EmptyResultsBanner from './EmptyResultsBanner';
import LoadingBanner from './LoadingBanner';
import PromptBanner from './PromptBanner';
import SingleImageItem from './SingleImageItem';

const ImageGallery = ({
  className,
  individualPageData,
  comparisonPageData,
  compareMode,
  isQueryLoading,
  hasUserQueried,
  pageIndex,
  onPrevPage,
  onNextPage,
  mapRef,
  individualResultsQueue,
  comparisonResultsQueue,
  geocodedLocation,
  onAddImage,
  selectedImageIds,
  isClassificationLoading,
}) => {
  const observer = useRef(null);

  // Avoid computing both array lengths for better performance
  const resultsCount = (
    compareMode ? comparisonResultsQueue : individualResultsQueue
  ).current.flat().length;

  const lastPageIndex = Math.max(Math.floor((resultsCount - 1) / IMAGE_GALLERY_PAGE_SIZE), 0);

  const isOnFirstPage = pageIndex === 0;
  const isOnLastPage = pageIndex === lastPageIndex;

  const isQueryPromptShowing = !isQueryLoading && !hasUserQueried;

  const isEmptyResultScreenShowing = !isQueryLoading && hasUserQueried && resultsCount === 0;

  const isPaginationShowing = !isQueryLoading && hasUserQueried && resultsCount !== 0;

  const addToObserver = useCallback(
    (imgRef) => {
      if (imgRef && observer.current) {
        observer.current.observe(imgRef);
      }
    },
    [observer]
  );

  const handleMarkerClick = useCallback((clickedCoordinate) => {
    if (mapRef.current) {
      mapRef.current.setView(clickedCoordinate, 17);
    } else {
      console.warn('Attempted to move to a marker without first initializing the map.');
    }
  }, []);

  // Setup lazy loading of images when they come into view
  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            const src = img.getAttribute('data-src');
            img.setAttribute('src', src);
            observer.current.unobserve(img);
          }
        });
      },
      {
        // Load the images 10px before they are visible
        rootMargin: '10px',
      }
    );

    return () => observer.current.disconnect();
  }, []);

  return (
    <div className={classNames('flex flex-col', className)}>
      {isQueryPromptShowing && <PromptBanner />}
      {isEmptyResultScreenShowing && <EmptyResultsBanner />}
      {isQueryLoading && <LoadingBanner />}
      {isPaginationShowing && (
        <div className="mx-1 mb-2 flex flex-col items-center border-b pb-1">
          <div className="font-medium text-slate-700 dark:text-slate-200">
            <span>Showing</span>{' '}
            <span>
              {pageIndex * IMAGE_GALLERY_PAGE_SIZE + 1} -{' '}
              {Math.min((pageIndex + 1) * IMAGE_GALLERY_PAGE_SIZE, resultsCount)}
            </span>
          </div>
        </div>
      )}
      <div className="h-[9/10] overflow-y-auto" id="image-gallery-container">
        {compareMode
          ? !isQueryLoading &&
            comparisonPageData.map((comparisonObject) => (
              <ComparisonImageItem
                key={`comparison-${comparisonObject[0].id}-vs-${comparisonObject[1].id}`}
                comparisonObject={comparisonObject}
                geocodedLocation={geocodedLocation}
                onCoordinateClick={handleMarkerClick}
                addToObserver={addToObserver}
                className="mb-2"
              />
            ))
          : !isQueryLoading &&
            individualPageData.map((imageObject) => {
              const isAdded = selectedImageIds.includes(imageObject.id);

              return (
                <SingleImageItem
                  key={imageObject.id}
                  id={imageObject.id}
                  src={imageObject.url}
                  coordinates={imageObject.coordinates}
                  note={imageObject.note}
                  geocodedLocation={geocodedLocation}
                  onCoordinateClick={handleMarkerClick}
                  addToObserver={addToObserver}
                  onAddImage={onAddImage}
                  isAdded={isAdded}
                  isAddingDisabled={isClassificationLoading || isAdded}
                  category={imageObject.category}
                  className="mb-2"
                />
              );
            })}
      </div>
      {isPaginationShowing && (
        <div className="mt-auto flex flex-row items-center justify-center py-2">
          <button
            className="mr-6 rounded-md bg-slate-100 px-3 py-1 transition-colors hover:bg-slate-200 disabled:cursor-not-allowed disabled:bg-slate-300 dark:bg-slate-600 dark:text-slate-200 dark:hover:bg-slate-700 dark:disabled:bg-slate-400"
            disabled={isOnFirstPage}
            onClick={onPrevPage}
          >
            <ChevronLeft />
          </button>
          <button
            className="rounded-md bg-slate-100 px-3 py-1 transition-colors hover:bg-slate-200 disabled:cursor-not-allowed disabled:bg-slate-300 dark:bg-slate-600 dark:text-slate-200 dark:hover:bg-slate-700 dark:disabled:bg-slate-400"
            disabled={isOnLastPage}
            onClick={onNextPage}
          >
            <ChevronRight />
          </button>
        </div>
      )}
    </div>
  );
};

ImageGallery.defaultProps = {
  geocodedLocation: null,
  className: '',
};

ImageGallery.propTypes = {
  className: PropTypes.string,
  individualPageData: PropTypes.arrayOf(PropTypes.object).isRequired,
  comparisonPageData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  compareMode: PropTypes.bool.isRequired,
  isQueryLoading: PropTypes.bool.isRequired,
  hasUserQueried: PropTypes.bool.isRequired,
  pageIndex: PropTypes.number.isRequired,
  onPrevPage: PropTypes.func.isRequired,
  onNextPage: PropTypes.func.isRequired,
  mapRef: PropTypes.any.isRequired,
  individualResultsQueue: PropTypes.any.isRequired,
  comparisonResultsQueue: PropTypes.any.isRequired,
  geocodedLocation: PropTypes.object,
  onAddImage: PropTypes.func.isRequired,
  selectedImageIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  isClassificationLoading: PropTypes.bool.isRequired,
};

export default ImageGallery;
