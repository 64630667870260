import React, { memo, useState } from 'react';

import classNames from 'classnames';
import { Check, Copy, Plus } from 'lucide-react';
import PropTypes from 'prop-types';

import { generateDistanceString, generateThumbnailUrlFromOriginalUrl } from '../utils';

import ImagePopup from './ImagePopup';

const SingleImageItem = ({
  id,
  src,
  coordinates,
  note,
  onCoordinateClick,
  addToObserver,
  onAddImage,
  isAdded,
  isAddingDisabled,
  geocodedLocation = null,
  className = '',
  category = '',
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);

  const thumbnailSource = generateThumbnailUrlFromOriginalUrl(src);
  const [latitude, longitude] = coordinates;
  const isCoordinateClickDisabled = latitude === null || longitude === null;

  const handleOnCopyToPasteboard = () => {
    if (!isCoordinateClickDisabled) {
      navigator.clipboard.writeText(`${latitude}, ${longitude}`);

      // Display a checkmark to let the user know they have copied it
      setIsCopied(true);

      // Undo the checkmark after a while
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    }
  };

  const handleAddImage = (event) => {
    // NOTE: Don't trigger the popup
    event.stopPropagation();

    onAddImage(id);
  };

  const handleOnCoordinateClick = () => {
    onCoordinateClick(coordinates);
  };

  const handleOnPreviewLoad = (event) => {
    event.target.style.opacity = 1;
  };

  const handleOnImagePopupClose = () => {
    setIsImagePopupOpen(false);
  };

  const handleOnPreviewClick = () => {
    setIsImagePopupOpen(true);

    // Check if the popup container exists and reset its scroll position
    const popupContainer = document.querySelector('.popup-container');
    if (popupContainer) {
      popupContainer.scrollTop = 0;
    }
  };

  return (
    <>
      <div
        className={classNames('max-w-lg rounded-md border border-transparent', className)}
        id={`image-item-${id}`}
      >
        <div
          className="image-placeholder single-image-item relative flex w-full cursor-pointer flex-row items-center justify-center rounded-t-md border-b border-slate-300 bg-slate-50 text-xl text-slate-300 dark:border-slate-700 dark:bg-slate-400"
          style={{ paddingBottom: '60%' }}
          onClick={handleOnPreviewClick}
        >
          {category && (
            <div className="absolute left-0 top-0 z-10 m-2 rounded bg-white bg-opacity-75 p-1 text-xs text-slate-700 shadow">
              {category}
            </div>
          )}
          <div className="absolute top-1/2 -mt-4">
            <span>Loading</span>
            <span className="loadingDots" />
          </div>
          <img
            className="absolute left-0 top-0 h-full max-h-[800px] w-full max-w-[1000px] rounded-t-md bg-slate-100 object-cover opacity-0 transition-opacity duration-500"
            data-src={thumbnailSource}
            alt={note}
            ref={addToObserver}
            loading="lazy"
            onLoad={handleOnPreviewLoad}
          />
          <button
            className="top-0 z-10 ml-auto mr-2 mt-2 rounded-full border-slate-600 bg-slate-50 p-1 text-slate-600 transition-colors hover:bg-slate-200 disabled:cursor-not-allowed"
            title={isAdded ? 'Already added' : 'Click to add'}
            type="button"
            onClick={handleAddImage}
            disabled={isAddingDisabled}
          >
            {isAdded ? <Check className="text-green-600" size={20} /> : <Plus size={20} />}
          </button>
        </div>
        <div className="mt-0 w-full rounded-b-md bg-slate-100 py-1.5 pl-3 pr-2 dark:bg-slate-600 dark:text-slate-200">
          {isCoordinateClickDisabled ? (
            <div className="flex w-full flex-col items-center">
              <button
                className="text-xs text-slate-700 hover:underline disabled:cursor-not-allowed dark:text-slate-200"
                title="No location available"
                disabled
              >
                <span className="font-medium">Coordinates: </span>
                <span className="text-slate-500 dark:text-slate-500">N/A</span>
              </button>
            </div>
          ) : (
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-col items-start">
                <button
                  className="text-xs text-slate-700 hover:underline  dark:text-slate-200"
                  onClick={handleOnCoordinateClick}
                  title="Go to pinned location on map"
                >
                  <span className="font-medium">Coordinates: </span>
                  <span className="text-slate-700 dark:text-slate-200">
                    {latitude.toFixed(3)}, {longitude.toFixed(3)}
                  </span>
                </button>
                {geocodedLocation !== null && (
                  <div className="text-xs text-slate-700 dark:text-slate-200">
                    <span className="font-medium">Distance to address: </span>
                    <span>
                      {generateDistanceString(coordinates, [
                        geocodedLocation.lat,
                        geocodedLocation.lng,
                      ])}
                    </span>
                  </div>
                )}
              </div>
              <button
                className={classNames(
                  'rounded-md p-1 text-slate-700 transition-colors hover:bg-slate-200 disabled:cursor-not-allowed dark:text-slate-200 dark:hover:bg-slate-800',
                  isCopied ? 'bg-slate-200 dark:bg-slate-800' : ''
                )}
                onClick={handleOnCopyToPasteboard}
                title="Copy coordinates to clipboard"
                disabled={isCopied}
              >
                {isCopied ? <Check className="text-green-600" size={16} /> : <Copy size={16} />}
              </button>
            </div>
          )}
        </div>
      </div>
      {isImagePopupOpen && <ImagePopup src={src} onClose={handleOnImagePopupClose} />}
    </>
  );
};

SingleImageItem.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  coordinates: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)])
  ).isRequired,
  note: PropTypes.string.isRequired,
  onCoordinateClick: PropTypes.func.isRequired,
  addToObserver: PropTypes.func.isRequired,
  onAddImage: PropTypes.func.isRequired,
  isAdded: PropTypes.bool.isRequired,
  isAddingDisabled: PropTypes.bool.isRequired,
  geocodedLocation: PropTypes.object,
  className: PropTypes.string,
  category: PropTypes.string,
};

SingleImageItem.defaultProps = {
  className: '',
  annotatable: false,
  geocodedLocation: null,
};

export default memo(SingleImageItem);
