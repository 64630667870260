import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const TeraLensIcon = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('h-7 w-7 text-blue-600', className)}
      fill="currentColor"
      strokeWidth="1"
      viewBox="0 0 27 27"
    >
      <path
        d="M22.6547 20.1278C22.2021 19.5222 21.5326 19.0566 20.5609 19.2084C18.315 19.5594 19.5081 17.8047 17.8939 18.5066C16.6025 19.0681 15.6713 20.6589 15.3672 21.3842C15.2736 20.9397 14.918 19.9664 14.2443 19.6295C13.402 19.2084 13.1213 19.9103 12.63 19.2084C12.1387 18.5066 12.1387 17.524 11.7176 16.5414C11.2965 15.5588 13.1213 14.0849 14.2443 14.6464C15.3672 15.2079 16.0691 14.857 16.6305 15.6992C17.192 16.5414 17.8939 17.5942 17.8939 14.6464C17.8939 11.6986 19.2976 10.3651 19.9292 10.4353C20.5609 10.5055 22.5261 9.80349 22.1752 9.10179C21.8242 8.40009 19.9292 8.04901 19.9292 7.41735C19.9292 6.78568 20.7715 5.45233 19.9292 4.82067C19.087 4.189 16.9815 2.92567 15.3672 3.06604C13.753 3.20641 13.2556 3.83849 13.2556 4.39997C13.2556 4.96145 15.3672 6.01439 14.2443 6.71587C13.1213 7.41735 9.47167 8.53994 9.47167 7.41735C9.47167 6.29475 9.26112 3.90846 9.96297 3.90846C10.6648 3.90846 10.8754 2.15384 11.2965 2.22402C11.7176 2.29421 13.4722 1.52262 14.2443 1.87332C14.4809 1.98084 15.2586 1.77834 16.1343 1.41344M22.6547 20.1278C24.1286 18.1347 25 15.6691 25 13C25 7.45694 21.2417 2.79168 16.1343 1.41344M22.6547 20.1278C20.4689 23.0835 16.9583 25 13 25C12.2953 25 11.6048 24.9393 10.9334 24.8227M10.9334 24.8227C11.0241 24.3006 11.1428 23.8692 11.2965 23.6301C11.9281 22.6475 14.0337 23.3494 14.2443 22.8581C14.4548 22.3668 13.5424 21.8053 12.63 21.3842C11.7176 20.9631 10.1735 20.542 9.96297 18.5066C9.75241 16.4712 8.76982 16.8221 8.55926 16.5414C8.34871 16.2607 5.89223 15.4886 5.11998 14.0849C4.34773 12.6812 5.1202 10.2949 5.11998 9.66327C5.11983 9.21675 3.18372 8.73743 1.87768 8.48668M10.9334 24.8227C5.29078 23.8433 1 18.9227 1 13C1 11.4036 1.31174 9.87994 1.87768 8.48668M1.87768 8.48668C3.66115 4.09599 7.96902 1 13 1C14.0844 1 15.1351 1.14383 16.1343 1.41344"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.9"
      />
    </svg>
  );
};

TeraLensIcon.propTypes = {
  className: PropTypes.string,
};

TeraLensIcon.defaultProps = {
  className: '',
};

export default TeraLensIcon;
