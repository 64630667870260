import React, { useEffect } from 'react';

import L from 'leaflet';
import PropTypes from 'prop-types';

import {
  API_RESULTS_LIMIT,
  DEFAULT_CENTROID,
  DEFAULT_ZOOM,
  INITIAL_RESULTS_SHOWN_PER_LAYER,
  MILE_IN_METERS,
} from '../constants';

// TODO: When storing the query, we're not conveying information about if geobounding was used (and how/which)
const Map = ({
  setGeocodedLocation,
  onSliderValueChange,
  mapRef,
  drawnItemsRef,
  geoboundaryRef,
  sliderControlRef,
  baseLayerControlRef,
  individualResultsLayerControlRef,
  comparisonResultsLayerControlRef,
  classificationResultsLayerControlRef,
  setScoreQueue,
}) => {
  useEffect(() => {
    // NOTE: For development purposes
    if (mapRef.current) {
      return;
    }

    const map = L.map('map', {
      center: DEFAULT_CENTROID,
      zoom: DEFAULT_ZOOM,
      attributionControl: false,
    });

    // Add base map (supports dark mode)
    L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 21,
      className: 'map-tiles',
    }).addTo(map);

    // Show scale in metric and imperial systems
    L.control.scale().addTo(map);

    // Add draw toolbar
    const drawnItems = L.featureGroup().addTo(map);
    map
      .addControl(
        new L.Control.Draw({
          draw: {
            polyline: false,
            polygon: true,
            marker: false,
            circle: true,
            circlemarker: false,
          },
          edit: {
            featureGroup: drawnItems,
          },
        })
      )
      .on(L.Draw.Event.CREATED, (event) => {
        // Only support a subset of shapes
        if (!['circle', 'polygon', 'rectangle'].includes(event.layerType)) {
          return;
        }

        const shape = event.layer;
        drawnItems.clearLayers();
        drawnItems.addLayer(shape);

        const geojson = shape.toGeoJSON();

        // NOTE: this makes the geojson non-standard
        if (shape instanceof L.Circle) {
          geojson.properties.radius = shape.getRadius();
        }

        geoboundaryRef.current = geojson;
      })
      .on(L.Draw.Event.DELETESTART, () => {
        // Disable filtering by geoboundary when shape is deleted
        geoboundaryRef.current = null;
        setGeocodedLocation(null);
      })
      .on(L.Draw.Event.DELETED, () => {
        // Disable filtering by geoboundary when shape is deleted
        geoboundaryRef.current = null;
        setGeocodedLocation(null);

        // Clear the report card
        setScoreQueue([]);
      });

    // Create the geocoder control
    L.Control.geocoder({
      defaultMarkGeocode: false,
      collapsed: false,
      position: 'topright',
      geocoder: new L.Control.Geocoder.Nominatim({
        geocodingQueryParams: {
          addressdetails: 1,
        },
      }),
    })
      .on('markgeocode', (event) => {
        const center = event.geocode.center;
        const address = event.geocode.name;

        // Manually create a marker and add it to the map with address popup
        const marker = L.marker(center).addTo(map);
        marker.bindPopup(address).openPopup();

        // Draw a circle centered on the geocoded location
        const circle = L.circle(center, { radius: 2 * MILE_IN_METERS });
        drawnItems.clearLayers();
        drawnItems.addLayer(circle);

        // Update geoboundary state
        const geojson = circle.toGeoJSON();

        // NOTE: this makes the geojson non-standard
        if (circle instanceof L.Circle) {
          geojson.properties.radius = circle.getRadius();
        }

        geoboundaryRef.current = geojson;
        setGeocodedLocation(event.geocode.center);

        // Zoom in to geocoded location
        const circleBounds = circle.getBounds();
        map.fitBounds(circleBounds, { maxZoom: 15 });

        // TODO: only reset report card if address has changed, currently it'll always reset when geocoding
        setScoreQueue([]);
      })
      .addTo(map);

    // Add the base layer controls
    // NOTE: Defined last so as to place it below the geocoding control
    const baseLayerControl = L.control
      .layers(undefined, undefined, {
        collapsed: false,
      })
      .addTo(map);

    // Add the results layer controls
    const individualResultsLayerControl = L.control
      .layers(undefined, undefined, {
        collapsed: false,
      })
      .addTo(map);

    // Add the results layer controls
    const comparisonResultsLayerControl = L.control
      .layers(undefined, undefined, {
        collapsed: false,
      })
      .addTo(map);

    // Add the results layer controls
    const classificationResultsLayerControl = L.control
      .layers(undefined, undefined, {
        collapsed: false,
      })
      .addTo(map);

    // Add slider to control how many results are being shown
    const sliderControl = L.control
      .slider(onSliderValueChange, {
        size: '256px',
        position: 'bottomright',
        min: 1,
        max: API_RESULTS_LIMIT,
        step: 1,
        value: INITIAL_RESULTS_SHOWN_PER_LAYER,
        collapsed: false,
        title: 'Control how many results are shown on the map',
      })
      .addTo(map);

    // Store references to DOM
    mapRef.current = map;
    drawnItemsRef.current = drawnItems;
    baseLayerControlRef.current = baseLayerControl;
    individualResultsLayerControlRef.current = individualResultsLayerControl;
    comparisonResultsLayerControlRef.current = comparisonResultsLayerControl;
    classificationResultsLayerControlRef.current = classificationResultsLayerControl;
    sliderControlRef.current = sliderControl;

    // Hide the controls on startup
    baseLayerControl.getContainer().style.display = 'none';
    individualResultsLayerControl.getContainer().style.display = 'none';
    comparisonResultsLayerControl.getContainer().style.display = 'none';
    classificationResultsLayerControl.getContainer().style.display = 'none';
  }, []);

  return (
    <div className="h-[100dvh] w-full outline-none">
      <div id="map" className="z-10 m-0 h-full w-full p-0" />
    </div>
  );
};

Map.propTypes = {
  geoboundaryRef: PropTypes.any,
  setGeocodedLocation: PropTypes.func.isRequired,
  onSliderValueChange: PropTypes.func.isRequired,
  mapRef: PropTypes.any.isRequired,
  drawnItemsRef: PropTypes.any.isRequired,
  individualResultsLayerControlRef: PropTypes.any.isRequired,
  comparisonResultsLayerControlRef: PropTypes.any.isRequired,
  classificationResultsLayerControlRef: PropTypes.any.isRequired,
  sliderControlRef: PropTypes.any.isRequired,
  baseLayerControlRef: PropTypes.any.isRequired,
  setScoreQueue: PropTypes.func.isRequired,
};

export default Map;
